/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, Fragment } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Images } from 'Constants';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, Collapse } from 'reactstrap';
import { Auth } from 'Service';
import { UserChangePwdModal, RemoveOldData } from 'Modals';
import Drawer from 'react-motion-drawer';
import {
  _,
  Http, API,
  // Utils 
} from 'Helpers';
import Role from 'Helpers/Role/Role';
import config from '../../config'

class AdminHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      RemoveOldDataModalShow: false,
      isDrawerOpen: false,
      SelectedMenu: '',
      currentUserMenu: '',
      isShow: 1,
      downloadPop: false,
      userAgentType: ""
    }
  }
  logout = () => {
    this.setState({ currentUser: undefined })
    localStorage.removeItem('currentUser')
    localStorage.removeItem('Acdata')
    Auth.logout()
    this.props.history.push('/')
  }
  componentDidMount() {

    var userAgent = this.getMobileOperatingSystem();
    this.setState({ userAgentType: userAgent });
    if (userAgent !== "unknown") {
      this.setState({ downloadPop: true })
    }


    let currentUser = JSON.parse(localStorage.getItem('currentUser'))
    this.setState({ currentUser: currentUser, currentUserMenu: currentUser });

    if (currentUser) {
      this.getUserDetail();
      if (currentUser.first_time_login == 0 && localStorage.getItem('firstlogin') == 0) {
        localStorage.setItem('firstlogin', 1);
        this.modalShow('UserChangePwdModalShow', currentUser)
      }
    }

  }
  componentWillReceiveProps() {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'))
    if (currentUser != this.state.currentUser) {
      // this.setState({ currentUser: currentUser });
      if (currentUser)
        this.getUserDetail();
    }
  }
  getUserDetail() {
    let param = {
    }
    this.setState({ posting: true })
    Http.post(API.PROFILE_DETAIL, param).then(response => {

      let data = { ...Auth.currentUserValue, ...response.data }
      localStorage.setItem('currentUser', JSON.stringify(data));

      this.setState({
        currentUser: data,
        posting: false
      })
    }).catch(error => {
      this.setState({ posting: false })
    });

  }
  modalShow = (name, item) => {
    this.setState({
      currentUser: item,
      [name]: true
    })
  }

  modalHide = (name) => {
    this.setState({
      isShow: 0,
      UserChangePwdModalShow: false,
      RemoveOldDataModalShow: false
    })
  }

  toggleDrawer = () => {
    this.setState({ isDrawerOpen: !this.state.isDrawerOpen });
  }

  onChangeDrawer = (e = false) => {
    let LockScreen = 'lock-screen'
    this.setState({ isDrawerOpen: e }, () => {
      if (e) {
        document.body.classList.add(LockScreen);
      } else {
        document.body.classList.remove(LockScreen);
      }
    });
  }

  toggleNav = (menu_id) => {
    this.setState({
      SelectedMenu: menu_id == this.state.SelectedMenu ? '' : menu_id
    });
  }


  getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      return "Android";
    }
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    // if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    //   return "iOS";
    // }
    return "unknown";
  }

  GotoLink = () => {

    if (this.state.userAgentType === 'Android') {
      if (config.is_live) {
        // window.open("https://betvik-master.s3.ap-south-1.amazonaws.com/app/betvik-admin.apk", "_blank");
        //window.location.href = "https://betvik-master.s3.ap-south-1.amazonaws.com/app/betvik-admin.apk";
      } else {
       // window.open("https://drive.google.com/file/d/1v2lpqYfpqj0vPzZhQHtsdRaiOcDfVX5X/view", "_blank");
      }

    }

    if (this.state.userAgentType === 'iOS') {
      window.open("https://apps.apple.com/in/app", "_blank");
    }

  }


  render() {
    const { currentUser, UserChangePwdModalShow, RemoveOldDataModalShow, isDrawerOpen, SelectedMenu, currentUserMenu, downloadPop, userAgentType } = this.state;
    const { match } = this.props;

    const UserChangePwdModalProps = {
      UserProps: currentUser,
      isOpen: UserChangePwdModalShow,
      toggle: this.modalHide,
      isFromSetting: true
    }
    const RemoveOldDataModalProps = {
      isOpen: RemoveOldDataModalShow,
      toggle: this.modalHide
    }
    const DrawerProps = {
      zIndex: 1030,
      noTouchOpen: true,
      handleWidth: 5,
      overlayColor: "rgba(0,0,0,0.78)",
      drawerStyle: {
        "boxShadow": "rgba(0, 0, 0, 0.188235) 0px 10px 20px, rgba(0, 0, 0, 0.227451) 0px 6px 6px"
      }
    }

    return (
      <Fragment>
        { currentUserMenu && <Drawer open={isDrawerOpen} className='custom-drawer' {...DrawerProps} onChange={this.onChangeDrawer} >
          {/* <div className={"drawer-header " + (currentUserMenu ? 'drawer-header-inner' : '')}>
            <i className="icon-cancel" onClick={() => this.toggleDrawer()} />
          </div> */}
          <div className={"drawer-body " + (currentUserMenu ? 'lg' : '')}>
            {
              currentUserMenu &&
              <Fragment>
                <ul className="admin-navigation drawer-mnu">
                  {
                    _.map(currentUserMenu.menu, (nav, key) => {
                      return (
                        _.isEmpty(nav.submenu) ?
                          <li {...{ key }}>
                            <NavLink to={match.url + nav.link} onClick={() => this.toggleDrawer()}>{nav.label}</NavLink>
                          </li>
                          :
                          <li {...{ key }}>
                            <div className="collapse-trigger" onClick={() => this.toggleNav(nav.menu_id)}>
                              <i className="icon-down" />
                              <a>{nav.label}</a>
                            </div>
                            <Collapse isOpen={SelectedMenu == nav.menu_id}>
                              <ul className='admin-navigation-inner'>
                                {
                                  _.map(nav.submenu, (item, key) => {
                                    return (
                                      <li {...{ key }}>
                                        <NavLink onClick={() => this.toggleDrawer()} to={match.url + item.link}>{item.label}</NavLink>
                                      </li>
                                    )
                                  })
                                }
                              </ul>
                            </Collapse>
                          </li>
                      )
                    })
                  }
                </ul>

              </Fragment>
            }



          </div>
        </Drawer>}
        <header {...{ className: `app-toolbar ${currentUser ? '' : 'max'}` }}>
          {currentUser && <i className="menu-tigger icon-menu" onClick={() => this.toggleDrawer()} />}
          <Link className='site-logo' to='/'><img src={Images.Logo} alt="" /></Link>
          {
            currentUser &&
            <Fragment>
              {/* {Number(currentUser.role) === 1 && <div className="admin-role-switch">
                <span className={`${ true ? 'active' : ''}`}>Indian Betting system</span>
                <span>Agent System</span>
              </div>} */}
              <div className="float-right">
                {Role.Staff != currentUser.role &&
                  <div className="display-board">
                    {/* <span>Liability: {currentUser.liability ? Number(currentUser.liability).toFixed(2) : '0'}</span> */}
                    {/* <span>Balance: {currentUser.balance ? Number(currentUser.balance).toFixed(2) : '0'}</span> */}
                    {/* <span>Total Balance: {currentUser.total_balance ? Number(currentUser.total_balance).toFixed(2) : '0'}</span> */}
                  </div>
                }
                <span className="avtarsap" />
                <div className="avtar-dropdown">
                  <div className="user-content">
                    <UncontrolledDropdown setActiveFromChild>
                      <DropdownToggle tag="div" className="name">
                        {currentUser.name ? currentUser.name : currentUser.username}
                        <div className="figure">
                          <img src={Images.AVTAR} alt="" />
                        </div>
                      </DropdownToggle>
                      <DropdownMenu right>                        
                        <span className='dropdown-item pointer' onClick={() => this.modalShow('UserChangePwdModalShow', currentUser)}>Change Password</span>
                        {Number(currentUser.role) === 1 && <span className='dropdown-item pointer' onClick={() => this.modalShow('RemoveOldDataModalShow', currentUser)}>Remove Old data</span>}                        
                        {Number(currentUser.role) === 1 && <Link className='dropdown-item pointer' to='/globalsetting'>Global Setting</Link>}
                        <span className='dropdown-item pointer' onClick={() => this.logout()}>Logout</span>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>

                </div>
                <a className='logout admin' onClick={() => this.logout()}><i className="icon-logout" /></a>
              </div>
            </Fragment>
          }
        </header>
        {UserChangePwdModalShow && <UserChangePwdModal {...UserChangePwdModalProps} />}
        {RemoveOldDataModalShow && <RemoveOldData {...RemoveOldDataModalProps} />}


        {
          // (downloadPop && userAgentType != "unknown" && !window.ReactNativeWebView) &&
          // <div className={`download-pop`}>
          //   {/* ${config.is_live ? 'd-none':''} */}
          //   <div className={'box-area'}>
          //     <img src={Images.LOGO_SM} alt="" />
          //     <h3>Want the best experience?</h3>
          //     <i className="close-btn icon-cancel" onClick={() => this.setState({ downloadPop: false })} />
          //     <div className={'plystore-btn-area'}>
          //       {
          //         userAgentType == "Android" ?
          //           <a onClick={this.GotoLink}>
          //             <img src={Images.ANDROID} alt={'Android'} />
          //           </a> :
          //           userAgentType == "iOS" ?
          //             <a onClick={this.GotoLink}>
          //               <img src={Images.APP_STORE} alt={'iOS'} />
          //             </a> :
          //             ""
          //       }

          //     </div>
          //   </div>
          // </div>
        }
      </Fragment>
    );
  }
}

export default AdminHeader;
