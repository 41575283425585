////new config.jsx

import _ from 'Helpers/_/_';

const DevHostname = [
    'localhost',
    'isocial-admin.vinfotechprojects.com'
    
]

const StagingHostname = [
    'isocial-admin.vinfotechprojects.com'
    
]

const regex = /(192)\.(168)(\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])){2}$/gm
const hostname = window.location.hostname;
const protocol = window.location.protocol;

const development = {
    s3: {
        BUCKET: "https://isocial-stg.s3.ap-south-1.amazonaws.com/appstatic/",
        BUCKET_DATA_PREFIX: "isocial-stg",
        BUCKET_STATIC_DATA_ALLOWED: "1",
    },
    apiGateway: {
        URL: protocol + "//isocial.vinfotechprojects.com/adminapi",
        // URL: protocol + "//192.168.5.171/isocial/Bet_Module/adminapi",
    },
    Socket: {
        URL: "https://isocial.vinfotechprojects.com:4000",
        Enable: true
    },
    cognito: {
    },
    CASINO_URL:'http://206.189.128.89/',
    is_live:false
};

const staging = {
    s3: {
        BUCKET: "https://isocial-stg.s3.ap-south-1.amazonaws.com/appstatic/",
        BUCKET_DATA_PREFIX: "isocial-stg",
        BUCKET_STATIC_DATA_ALLOWED: "1",
    },
    apiGateway: {
        URL: protocol + "//isocial.vinfotechprojects.com/adminapi",
    },
    Socket: {
        URL: protocol + "//isocial.vinfotechprojects.com:4000",
        Enable: true
    },
    cognito: {
    },
    CASINO_URL:'https://casinoapi.in/',
    is_live:false
};

const prod = {
    s3: {
        BUCKET: "https://bebetta-betting-bucket.s3.ap-south-1.amazonaws.com/appstatic/",
        BUCKET_DATA_PREFIX: "bebetta-betting-bucket",
        BUCKET_STATIC_DATA_ALLOWED: "1",
    },
    apiGateway: {
        URL: protocol + "//betwebview.bebetta.in/adminapi",
    },
    Socket: {
        URL: protocol + "//betportalstagingnode.bebetta.in/",
        Enable: true
    },
    cognito: {
    },
    CASINO_URL:'https://casinoapi.in/',
    is_live:true
};

// const config = _.includes(StagingHostname, hostname) || hostname.match(regex)
//     ? dev
//     : prod;

// export default {
//     ...config
// };

const config = _.includes(DevHostname, hostname) || hostname.match(regex)
    ? (_.includes(StagingHostname, hostname) ? staging : development)
    : prod;

console.log('config',config)

export default {
    ...config
};