/* eslint-disable eqeqeq */
import React, { Component, Fragment } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Notification from 'react-notify-toast';
import './App.scss';
import { AdminLayout } from "Layouts";
import {  MaintenanceView } from "Components";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: null,
      MaintenanceShow:false,
    };
  }

  render() {
    const { MaintenanceShow } = this.state;
    return (
      MaintenanceShow ? 
      <MaintenanceView />
      :
      <Fragment>
        <Notification options={{ zIndex: 99999 }} />
        <BrowserRouter>
          <Switch>
            <Route exact component={AdminLayout} />
            <Redirect from="*" to='/' />
          </Switch>
        </BrowserRouter>
      </Fragment>
    )
  }
}

export default App;
