// wrapper for localstorage and auth object setting

export function getAuth() {
  const auth = localStorage.getItem('auth');
  if (!auth) {
    return null;
  }
  return JSON.parse(auth);
}

export function setAuth(auth) {
  localStorage.setItem('auth', JSON.stringify(auth));
}

export function removeAuth() {
  localStorage.removeItem('auth');
}

export default {getAuth, setAuth, removeAuth};