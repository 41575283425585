import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, FormGroup, Input, Label } from 'reactstrap';
import moment from 'moment';
import { Utils, Http, API } from 'Helpers';

class ViewUserAccountModal extends Component {
  constructor(props){
    super(props);
    this.state = {
      name: this.props.UserProps.name,
      formValid: false,
    }
  }

  validateForm() {
    const {
      name,
    } = this.state;
    this.setState({
      formValid:
        name !== ''
        && Utils.isInvalid("required", name) === false
      });
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({ posting: true })
    const {
      name,
    } = this.state;

    let param = {
      "name":name,
      "user_guid":this.props.UserProps.user_guid
    }

    Http.post(API.UPDATE_USER_VIEW, param).then(response => {
      this.setState({ posting: false },this.props.toggle)
      Utils.notify(response.message);
    }).catch(error => {
      this.setState({ posting: false })
    });
  }

  //handlers 
  handleChange = (e, type) => {
    const name = !type ? e.target.name : type;
    let value = !type ? e.target.value : e;
    if (name === 'password') {
      value = value.trim()
    }
    this.setState({ [name]: value }, () => {
      this.validateForm()
    });
  };

  render() {
    const { isOpen, toggle,UserProps } = this.props
    const {name, formValid,posting } = this.state
    const ModalProps = {
      isOpen,
      toggle,
      size: 'lg',
      className: 'custom-modal',
    }

    return (
      <Modal {...ModalProps}>
        <i className="close-btn icon-cancel" onClick={toggle} />
    <ModalHeader>View Account For {UserProps.username}</ModalHeader>
          <ModalBody>
            <Row>
              <Col xs={12} md={4}>
              <FormGroup>
                  <Label>Name</Label>
                  <Input
                    type="text"
                    name="name"
                    value={name}
                    onChange={this.handleChange}
                    autoComplete="off"
                    invalid={Utils.isInvalid("required", name)}
                  />
                </FormGroup>
                {/* <FormGroup>
                  <Label>Name</Label>
                  <div className="value">{UserProps.name}</div>
                </FormGroup> */}
              </Col>
              <Col xs={12} md={4}>
                <FormGroup>
                  <Label>User ID</Label>
                  <div className="value">{UserProps.username}</div>
                </FormGroup>
              </Col>
              <Col xs={12} md={4}>
                <FormGroup>
                  <Label>Opening Date</Label>
                  <div className="value">{moment(UserProps.opening_date).format('MMM, DD YYYY')}</div>
                </FormGroup>
              </Col>
              
            </Row>
            <Row>
            <Col xs={12} md={4}>
                <FormGroup>
                  <Label>Opening Balance</Label>
                  <div className="value">{UserProps.opening_balance}</div>
                </FormGroup>
              </Col>
              <Col xs={12} md={4}>
                <FormGroup>
                  <Label>Password</Label>
                  <div className="value">************</div>
                </FormGroup>
              </Col>
              <Col xs={12} md={4}>
                <FormGroup>
                  <Label>Confirm Password</Label>
                  <div className="value">************</div>
                </FormGroup>
              </Col>
              
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Remark</Label>
                  <div className="value">{UserProps.remark} </div>
                </FormGroup>
              </Col>
            </Row>


          </ModalBody>
          <ModalFooter>
            <Button color="primary" size='sm' type='submit' disabled={!formValid || posting}  onClick={this.onSubmit}>Done</Button>
          </ModalFooter>
      </Modal>
    );
  }
}

ViewUserAccountModal.defaultProps = {
  isOpen: true,
  toggle: () => { }
}

export default ViewUserAccountModal;
