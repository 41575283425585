/* eslint-disable jsx-a11y/anchor-is-valid, eqeqeq */
import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, Row, Col, FormGroup, Input, Label } from 'reactstrap';
import {_, Utils, Http, API } from 'Helpers';
import Select from 'react-select';

class AddStaffModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posting: false,
      formValid: false,
      name: '',
      selectedGroup: '',
      password: '',
      confirm_password: '',
      groupList:[],
    }
  }
componentDidMount()
{
const{UserProps}=this.props;
  if(UserProps){
   this.setState({name:UserProps.name,user_name:UserProps.username})
  }
this.getGroupList();
}
  getGroupList() {
    let param = {
    }
    this.setState({ posting: true })
    Http.post(API.GET_ADMIN_GROUP, param).then(response => {
      const{UserProps}=this.props;
  
      this.setState({
        groupList: response.data.group_list,
        posting: false
      },()=>{
        if(UserProps){
          _.map(this.state.groupList,(item,index)=>{
            if(item.user_group_id==UserProps.user_group_id)
            this.setState({selectedGroup:item})
          })
         }
      })

    }).catch(error => {
      this.setState({
        posting: false
      })
    });

  }
  handleChange = (e, type) => {
    const name = !type ? e.target.name : type;
    let value = !type ? e.target.value : e;
    if (name === 'password') {
      value = value.trim()
    }
    this.setState({ [name]: value }, () => {
      this.validateForm()
    });
  };
  validateForm() {
    const{UserProps}=this.props;

    const {
      name,
      user_name,
      password,
      selectedGroup
    } = this.state;
    if(!UserProps){
      this.setState({
        formValid:
          name != ''
          && user_name != ''
          && password != ''
          && selectedGroup != ''
          && Utils.isInvalid("required", name) == false
          && Utils.isInvalid("required", user_name) == false
          && Utils.isInvalid("Password", password) == false
        });
    }
    else{
      var pass = true;
      if(password != ''){
        pass = Utils.isInvalid("Password", password) == false;
      }
      this.setState({
        formValid:
          name != ''
          && user_name != ''
          && selectedGroup != ''
          && Utils.isInvalid("required", name) == false
          && Utils.isInvalid("required", user_name) == false
          && pass
        });
    }
    
  }
  onSubmit = (e) => {
    const{UserProps}=this.props;
    e.preventDefault();
    this.setState({ posting: true })
    const {
      name,
      user_name,
      password,
      selectedGroup
    } = this.state;

    let param = {
      name:name,
      username:user_name,
      user_group_id:selectedGroup.user_group_id,
      password:password
    
     }
if(UserProps)
{
  param['user_guid']=UserProps.user_guid;
}else{
  param['password']=password;
}
    Http.post(UserProps?API.UPDATE_ADMIN:API.SAVE_ADMIN, param).then(response => {
      this.setState({ posting: false },this.props.callBack)
      Utils.notify(response.message);
    }).catch(error => {
      this.setState({ posting: false })
    });
  }

  render() {
    const { isOpen, toggle } = this.props
    const ModalProps = {
      isOpen,
      toggle,
      size: 'md',
      selectedGroup:'',
      className: 'custom-modal'
    }
    const {
      formValid,
      posting,
      name,
      password,
      user_name,
      selectedGroup,
      groupList
    } = this.state


    console.log(formValid, Utils.isInvalid("Password", password), 'form')

    return (
      <Modal {...ModalProps}>
        <i className="close-btn icon-cancel" onClick={toggle} />
        <ModalHeader>Add/Update Staff</ModalHeader>
        <Form>
          <ModalBody>
            <Row>
              <Col xs={12} md={6}>
                <FormGroup>
                  <Label>Name</Label>
                  <Input type="text"
                    name="name"
                    value={name}
                    autocomplete="off"
                    onChange={this.handleChange}
                    invalid={Utils.isInvalid("required", name)}
                  />
                </FormGroup>
              </Col>
              <Col  xs={12} md={6}>
                <FormGroup>
                  <Label>User Name</Label>
                  <Input type="text"
                    name="user_name"
                    value={user_name}
                    autocomplete="off"
                    onChange={this.handleChange}
                    invalid={(Utils.isInvalid("required", user_name))}

                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
             {
              <Col  xs={12} md={6}>
                <FormGroup>
                  <Label>Password</Label>
                  <Input type="password"
                    name="password"
                    value={password}
                    autoComplete="new-password"
                    onChange={this.handleChange}
                    invalid={Utils.isInvalid("Password", password)}
                  />
                </FormGroup>
              </Col>}
              <Col  xs={12} md={6}>
                <div className="sports-filter mb20">
                  <h4 className="head-medium">Select Group</h4>
                  <Select
                    className='react-select small-radius-select'
                    classNamePrefix="react-select"
                    value={selectedGroup}
                    options={groupList}
                    getOptionLabel={({ group_name }) => group_name}
                    getOptionValue={({ user_group_id }) => user_group_id}
                    onChange={(SelectItem) => {
                      this.setState({ selectedGroup: SelectItem }, () => {
                        this.validateForm()
                      });
                    }}
                  />
                </div>
              </Col>
            </Row>

          </ModalBody>
          <ModalFooter>
            <Button color="primary" size='sm' onClick={this.onSubmit} disabled={!formValid || posting}>Submit</Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}
AddStaffModal.defaultProps = {
  isOpen: true,
  toggle: () => { }
}
export default AddStaffModal;
