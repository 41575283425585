import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, Row, Col, FormGroup, Input, Label } from 'reactstrap';
import { _, Http, API } from 'Helpers';
import { notify } from 'react-notify-toast';
/* eslint-disable eqeqeq */

class UserSettingModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posting: false,
      formValid: false,
      userSetingList: [],
      commision_percentage:'',
    }
  }
  addIntialSeting() {
    let list = [];
    list.push(this.IR(0));
    list.push(this.IR(1));
    list.push(this.IR(2));
    list.push(this.IR(3));
    list.push(this.IR(4));
    list.push(this.IR(5));
    this.setState({ userSetingList: list })
  }
  IR(idx) {
    const { UserProps } = this.props
    
    let itm = {
      min_odd_stack_l: "Min Odd Stack",
      max_odd_stack_l: "Max Odd Stack",
      odds_delay_l: "Odds Delay",
      odds_win_commission_l: "Odds Win Comission",
      fancy_delay_l: "Fancy Delay",
      min_fancy_stack_l: "Min Fancy Stack",
      max_fancy_stack_l: "Max Fancy Stack",
      fancy_win_commission_l: "Fancy Win Comission",
      min_odd_stack: "",
      max_odd_stack: "",
      odds_delay: "",
      odds_win_commission: "",
      fancy_delay: "",
      min_fancy_stack: "",
      max_fancy_stack: "",
      fancy_win_commission: "",
      min_odd_stack_error: "",
      max_odd_stack_error: "",
      odds_delay_error: "",
      odds_win_commission_error: "",
      fancy_delay_error: "",
      min_fancy_stack_error: "",
      max_fancy_stack_error: "",
      fancy_win_commission_error: "",
    }

    switch (idx) {
      case 0:
        itm.user_sports_settings_id = 1;
        itm.user_id = UserProps.user_id;
        itm.sports_id = 2;
        itm.Label = "Cricket";
        return itm;

      case 1:
        itm.user_sports_settings_id = 2;
        itm.user_id = UserProps.user_id;
        itm.sports_id = 2;
        itm.Label = "Fancy Cricket";
        return itm;

      case 2:
        itm.user_sports_settings_id = 3;
        itm.user_id = UserProps.user_id;
        itm.sports_id = 1;
        itm.Label = "Soccer";
        return itm;

      case 3:
        itm.user_sports_settings_id = 3;
        itm.user_id = UserProps.user_id;
        itm.sports_id = 3;
        itm.Label = "Tennis";
        return itm;

      case 4:
        itm.user_sports_settings_id = 4;
        itm.user_id = UserProps.user_id;
        itm.sports_id = 4;
        itm.Label = "Horse Racing";
        return itm;
      case 5:
        itm.user_sports_settings_id = 5;
        itm.user_id = UserProps.user_id;
        itm.sports_id = 5;
        itm.Label = "Live Cards";
        return itm;
      default:
        return itm;


    }

  }
  updateSetting(data) {
    const { userSetingList } = this.state;
    for (let i = 0; i < userSetingList.length; i++) {
      let parentItem = userSetingList[i];

      for (let j = 0; j < data.length; j++) {
        let childItem = data[j];
        if (parentItem.sports_id == childItem.sports_id) {
          parentItem.min_odd_stack = childItem.min_odd_stack;
          parentItem.max_odd_stack = childItem.max_odd_stack;
          parentItem.odds_delay = childItem.odds_delay;
          parentItem.min_odd_stack = childItem.min_odd_stack;
          parentItem.odds_win_commission = childItem.odds_win_commission;
          parentItem.fancy_delay = childItem.fancy_delay;
          parentItem.min_fancy_stack = childItem.min_fancy_stack;
          parentItem.max_fancy_stack = childItem.max_fancy_stack;
          parentItem.fancy_win_commission = childItem.fancy_win_commission;
        }
      }
    }
    this.setState({ userSetingList: this.state.userSetingList })

  }
  componentDidMount() {
    const { UserProps } = this.props
    this.addIntialSeting();
    this.getUserSetting(UserProps.user_guid);
  }
  validateForm() {
    const {
      userSetingList
    } = this.state;
    _.map(userSetingList, () => {

    })

  }
  getUserSetting(user_guid) {
    let param = {
      user_guid: user_guid ? user_guid : '', // currentUser.user_guid,
    }

    Http.post(API.GET_SPORTS_SETTINGS, param).then(response => {
      if(response.data.commission_percent)
      this.setState({
        commision_percentage:response.data.commission_percent, 
        casino_commission_percent:response.data.casino_commission_percent,
        livecasino_commission_percent:response.data.livecasino_commission_percent
      })
      if (response.data.setting_data.length > 0) {
        this.updateSetting(response.data.setting_data);
      } else {
        this.addIntialSeting();
      }
    }).catch(error => {
    });

  }
  saveUserSetting(user_guid) {

    let param = {
      user_guid: user_guid ? user_guid : '',
      commission_percent:this.state.commision_percentage,
      setting_data: this.mergeFancy(),
      casino_commission_percent:this.state.casino_commission_percent,
      livecasino_commission_percent:this.state.livecasino_commission_percent
    }
    Http.post(API.SAVE_SPORTS_SETTINGS, param).then(response => {
      notify.show('User settings updated successfully', "success", 2500);
      this.props.toggle()
    }).catch(error => {
      this.refreshErrors(error.error);
    });

  }
  
  refreshErrors(error){
    const{userSetingList}=this.state;

    for (var i=0;i<userSetingList.length;i++) {
      let item=userSetingList[i];
      item.min_odd_stack_error= "";
      item.max_odd_stack_error= "";
      item.odds_delay_error= "";
      item.odds_win_commission_error= "";
      item.fancy_delay_error= "";
      item.min_fancy_stack_error= "";
      item.max_fancy_stack_error= "";
      item.fancy_win_commission_error= "";
    }
    this.setState({userSetingList:userSetingList},()=>{
      this.updateErrors(error);
    })

  }
  updateErrors(error){
    const{userSetingList}=this.state;
    for (var key in error) {
      for (var i=0;i<userSetingList.length;i++) {
      
        if(key==userSetingList[i].sports_id){
           var errorData= error[key];

          for (var errorDataKey in errorData) {
            userSetingList[i][errorDataKey+'_error']=errorData[errorDataKey]
          }
        }
      } 
    }
    this.setState({userSetingList:userSetingList})
  }
  mergeFancy() {
    let tempList = [...[], ...this.state.userSetingList]
    tempList[0].min_fancy_stack = tempList[1].min_fancy_stack;
    tempList[0].max_fancy_stack = tempList[1].max_fancy_stack;
    tempList[0].fancy_delay = tempList[1].fancy_delay;
    tempList[0].fancy_win_commission = tempList[1].fancy_win_commission;
    tempList.splice(1, 1);
    return tempList;

  }
  onSubmit(type) {
    this.setState({ posting: true })
    const { UserProps } = this.props

    const {
      amount,
      remark,
    } = this.state;

    let param = {
      user_guid: UserProps.user_guid,
      amount,
      remark,
      type
    }

    Http.post(API.BALANCE_IN_OUT, param).then(response => {
      this.setState({ posting: false }, this.props.toggle)
    }).catch(error => {
      this.setState({ posting: false })
    });
  }
  handleOtherChange=(e,type)=>{
    const name = !type ? e.target.name : type;
    let value = !type ? e.target.value : e;
    const re = /^[0-9]+\.?[0-9]*$/;
    if (value == '') {
      this.setState({[name]:value})
    }else{
      if (re.test(value)) {
        this.setState({[name]:value})
      }
    }
  }
  handleChange = (e, type) => {
    const re = /^[0-9]+\.?[0-9]*$/;
    let index = e.target.id;
    const name = !type ? e.target.name : type;
    let value = !type ? e.target.value : e;
    if (value == '') {
      let userSetingList = this.state.userSetingList;
      userSetingList[index][name] = value
      this.setState({ userSetingList: userSetingList }, () => {
        this.validateForm()
      });
    } else {
      if (re.test(value)) {
        let userSetingList = this.state.userSetingList;
        userSetingList[index][name] = value
        this.setState({ userSetingList: userSetingList }, () => {
          this.validateForm()
        });
      }
    }
  };
renderErrors(item,param){ 
if(item[param] && item[param].length>0)  
return <span className='error-block'>{item[param]}</span>
else return null;
}
  render() {
    const { isOpen, toggle } = this.props
    const ModalProps = {
      isOpen,
      toggle,
      size: 'xl',
      className: 'custom-modal'
    }
    const { userSetingList,commision_percentage, casino_commission_percent, livecasino_commission_percent } = this.state;
    const { UserProps } = this.props

    return (
      <Modal {...ModalProps}>
        <i className="close-btn icon-cancel" onClick={toggle} />
        <ModalHeader>User Setting</ModalHeader>
        <Form>
          <ModalBody>
          {(UserProps.role == '1' || UserProps.role == '2') &&<Col>
          <Row><Col className='h5 m-b-20'>Admin Setting</Col></Row>
           <Row>
            
                    <Col xs={12} md={4}>
                      <FormGroup>
                        <Label>Commision Percentage</Label>
                        <Input type="numric"
                          name={"commision_percentage"}
                          id={1}
                          value={commision_percentage}
                          onChange={this.handleOtherChange}
                          autoComplete="off"
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={4}>
                      <FormGroup>
                        <Label>Cards Commision Percentage</Label>
                        <Input type="numric"
                          name={"casino_commission_percent"}
                          id={1}
                          value={casino_commission_percent}
                          onChange={this.handleOtherChange}
                          autoComplete="off"
                        />
                      </FormGroup>
                    </Col>

                    <Col xs={12} md={4}>
                      <FormGroup>
                        <Label>Casino Commision Percentage</Label>
                        <Input type="numric"
                          name={"livecasino_commission_percent"}
                          id={1}
                          value={livecasino_commission_percent}
                          onChange={this.handleOtherChange}
                          autoComplete="off"
                        />
                      </FormGroup>
                    </Col>
                    
                </Row>
          </Col>}
            {_.map(userSetingList, (item, idx) => {
              return (
                <Col key={idx}>
                  <Row><Col className='h5 m-b-20'>{item.Label}</Col></Row>
                  <Row>
                    <Col xs={12} md={4}>
                      <FormGroup>
                        <Label>{idx === 1 ? item.min_fancy_stack_l : item.min_odd_stack_l}</Label>
                        <Input type="numric"
                          name={idx === 1 ? "min_fancy_stack" : "min_odd_stack"}
                          id={idx}
                          value={idx === 1 ? item.min_fancy_stack : item.min_odd_stack}
                          onChange={this.handleChange}
                          autoComplete="off"
                        />
                        {this.renderErrors(item,idx === 1 ? "min_fancy_stack_error" : "min_odd_stack_error")}
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={4}>
                      <FormGroup>
                        <Label>{idx === 1 ? item.max_fancy_stack_l : item.max_odd_stack_l}</Label>
                        <Input type="numric"
                          name={idx === 1 ? "max_fancy_stack" : "max_odd_stack"}
                          id={idx}
                          value={idx === 1 ? item.max_fancy_stack : item.max_odd_stack}
                          onChange={this.handleChange}
                        />
                        {this.renderErrors(item,idx === 1 ? "max_fancy_stack_error" : "max_odd_stack_error")}
                      </FormGroup>
                    </Col>
                    {idx !== 5 && <Col xs={12} md={4}>
                      <FormGroup>
                        <Label>{idx === 1 ? item.fancy_delay_l : item.odds_delay_l}</Label>

                        <Input type="numric"
                          name={idx === 1 ? "fancy_delay" : "odds_delay"}
                          id={idx}
                          onChange={this.handleChange}
                          value={idx === 1 ? item.fancy_delay : item.odds_delay}
                        />
                        {this.renderErrors(item,idx === 1 ? "fancy_delay_error" : "odds_delay_error")}
                      </FormGroup>
                    </Col>}
                    {/* {(UserProps.role == '1' || UserProps.role == '2') && <Col>
                      <FormGroup>
                        <Label>{idx === 1 ? item.fancy_win_commission_l : item.odds_win_commission_l}</Label>

                        <Input type="numric"
                          name={idx === 1 ? "fancy_win_commission" : "odds_win_commission"}
                          id={idx}
                          onChange={this.handleChange}
                          value={idx === 1 ? item.fancy_win_commission : item.odds_win_commission}
                        />
                        {this.renderErrors(item,idx === 1 ? "fancy_win_commission_error" : "odds_win_commission_error")}
                      </FormGroup>
                    </Col>} */}
                  </Row>
                </Col>
              )
            })}

          </ModalBody>
          <ModalFooter>
            <Button color="primary" size='sm' onClick={() => this.saveUserSetting(UserProps.user_guid)}>Change</Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}

UserSettingModal.defaultProps = {
  isOpen: true,
  toggle: () => { }
}

export default UserSettingModal;
