const Images = {
  Logo               : require('../../Assets/img/logo.svg'),
  DRAWER_LOGO        : require('../../Assets/img/drawer-logo.png'),
  THUMB              : require('../../Assets/img/thumb.jpg'),
  THUMB_WIDE         : require('../../Assets/img/thumb-wide.jpg'),
  THUMB_VERTICAL     : require('../../Assets/img/thumb-vertical.png'),
  MGA                : require('../../Assets/img/mga.png'),
  GAMBLING_COMMISSION: require('../../Assets/img/gambling-commission.png'),
  NET_BANKING        : require('../../Assets/img/net-banking.png'),
  UPI                : require('../../Assets/img/upi.png'),
  PAYTM              : require('../../Assets/img/paytm.png'),
  GOOGLE_PAY         : require('../../Assets/img/google-pay.png'),
  PHONE_PE           : require('../../Assets/img/phone-pe.png'),
  LOCK_IC           : require('../../Assets/img/lock_ic.png'),
  AVTAR           : require('../../Assets/img/avatar.png'),
  CHECKED           : require('../../Assets/img/checked-green.png'),
  UNCHECKED           : require('../../Assets/img/un-checked-green.png'),
  LOCK_USER           : require('../../Assets/img/lock_user.png'),
  STOP_WATCH           : require('../../Assets/img/clock.png'),
  PLAY_STORE         :require('../../Assets/img/playstore.png'),
  ANDROID            :require('../../Assets/img/android.png')
}

export default Images
