/* eslint-disable jsx-a11y/anchor-is-valid, eqeqeq */
import React, { Component, Fragment } from 'react';
import { AdminHeader, PrivateRoute  } from 'Components';
import { Route, Switch, Redirect, NavLink } from "react-router-dom";
import { _ } from 'Helpers';
import { Auth } from 'Service';
import router from "../../Router";
import { Collapse } from 'reactstrap';
import Role from 'Helpers/Role/Role';

const { AdminRoute, DefaultAdminRoute } = router;
// const isActive = onActive => (match, location) => {
//   if (match) {
//     onActive(location);
//   }
//   return match;
// }

class AdminLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
     pathname:'',
     SelectedMenu: ''
    }
  }
  // updateState(item){
    
  //   if(item && item.path)
  //       this.setState({pathname:item.path},()=>{
  //       console.log('logs',this.state.pathname)
  //       })
        
  // }
  toggleNav = (menu_id) => {
    this.setState({
      SelectedMenu: menu_id == this.state.SelectedMenu ? '' : menu_id
    });
  }
  render() {
    const { match } = this.props;
    

    const { SelectedMenu } = this.state;
    const currentUser = Auth.currentUserValue;
   // const onActive = (pathname) =>this.updateState(pathname);

//   console.log(currentUser.menu)

   /* const MenuID = {
     "1": `${match.url}user`,
     "2": `${match.url}market-watch`,
     "3": `${match.url}active-matches`,
     
    //  Reports
     "4" : `${match.url}reports`,
     "7" : `${match.url}bet-history`,
     "8" : `${match.url}account-statement`,
     "9" : `${match.url}profit-and-loss-by-match`,
     "10": `${match.url}profit-and-loss-by-downline`,
     "11": `${match.url}delete-bets`,

    //  Settings
     "5" : `${match.url}sport-setting`,
     "12": `${match.url}settings`,
     "13": `${match.url}series-settings`,
     "15": `${match.url}manage-fancy`,
     "16": `${match.url}clear-all-data`,
     "17": `${match.url}match-result`,
     "18": `${match.url}match-rollback`,
     "19": `${match.url}delete-declared-market`,

    //  Staff
     "6": `${match.url}staff`,
     "20": `${match.url}privileges-group`,
     "21": `${match.url}staff-list`,

     "22": `${match.url}manage-series`,
   } */

    return (
      <Fragment>
        <AdminHeader {...this.props} />
        <div className="admin-container">
          {
            currentUser &&
            <Fragment>
              {
                false ?
                <div className="admin-navigation">
                  
                  <NavLink  to={`${match.url}user`}>User</NavLink>
                  <NavLink  to={`${match.url}market-watch`}>Market Watch</NavLink>
                  <NavLink  to={`${match.url}active-matches`}>Active Matches</NavLink>
                  {currentUser.role==Role.SuperAdmin &&<NavLink  to={`${match.url}manage-series`}>Manage Series/Matches</NavLink>}
                  <NavLink  to={`${match.url}reports`}>Reports</NavLink>
                  <NavLink  to={`${match.url}match-result`}>Match Result</NavLink>
                  <NavLink to={`${match.url}sport-setting`}>Sport setting</NavLink>
                  <NavLink to={`${match.url}manage-fancy`}>Manage Fancy</NavLink>
                  <NavLink to={`${match.url}staff`}>Staff</NavLink>                
                  <NavLink to={`${match.url}staff-privileges-group`}>Privileges Group</NavLink>
                  <NavLink to={`${match.url}banner-type`}>Banner Type</NavLink>                 
                  <NavLink to={`${match.url}banner-list`}>Banner List</NavLink>                 
                  <NavLink to={`${match.url}team-list`}>Teams</NavLink>                 
                  <NavLink to={`${match.url}bet-template`}>Bet Template</NavLink>
                  <NavLink to={`${match.url}book_mark`}>BookMark Log</NavLink>                
                  {/* <div  onClick ={()=>{
                    this.updateState({path:'/staff'})
                  }}text="Staff" to={`${match.url}staff`}>
                      <span className={'custom-link '+ (this.state.pathname=='/staff'?'active':'')}>Staff</span>
                  </div> */}
                </div>
                :
                <ul className="admin-navigation">
                  {
                    _.map(currentUser.menu, (nav, key) => {
                      return (
                        _.isEmpty(nav.submenu) ?
                        <li {...{ key }}>
                          <NavLink to={match.url+nav.link}>{nav.label}</NavLink>
                        </li>
                        :
                        <li {...{ key }}>
                          <div className="collapse-trigger" onClick={() => this.toggleNav(nav.menu_id)}>
                            <i className="icon-down"/>
                            <a>{nav.label}</a>
                          </div>
                          <Collapse isOpen={SelectedMenu == nav.menu_id}>
                            <ul className='admin-navigation-inner'>
                              {
                                _.map(nav.submenu, (item, key) => {
                                  return (
                                    <li {...{key}}>
                                      <NavLink to={match.url+item.link}>{item.label}</NavLink>
                                    </li>
                                  )
                                })
                              }
                            </ul>
                          </Collapse>
                        </li>
                      )
                    })
                  }
                </ul>

              }

            </Fragment>
          }
          <div className="admin-switch">
            <Switch>
              {
                _.map(AdminRoute, (route, idx) => {
                  return route.component ?
                    (
                      route.isAuthenticate ? 
                      <PrivateRoute key={idx} path={match.url + route.path} exact={route.exact} name={route.name} component={route.component} />
                      :
                      <Route key={idx} path={match.url + route.path} exact={route.exact} name={route.name} component={route.component} />
                    )
                    :
                    null
                })
              }
              <Redirect from="*" to={match.url + DefaultAdminRoute.path} />
            </Switch>
          </div>
        </div>
      
      </Fragment>
    );
  }
}

export default AdminLayout;
