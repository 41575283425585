/* eslint-disable jsx-a11y/anchor-is-valid, eqeqeq */
import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, FormGroup, Label } from 'reactstrap';
import Select from 'react-select';
import { _, Http, API, Utils } from 'Helpers';

class MatchResultSportsbookModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posting: false,
      formValid: false,
      ResultValue: '',

    }
  }


  onSubmit = (data) => {
    const { selectedItem } = this.props
    
    let param = {
      "market_event_id": selectedItem.market_event_id,
      "market_name":selectedItem.market_name,
      "selection_id": data.selection_id
    }
    Http.post(API.UPDATE_SP_SETTLEMENT_RESULT, param).then(response => {
      Utils.notify(response.message);
      this.props.toggle()
      this.props.callback()
    }).catch(error => {
      this.setState({ posting: false })
    });

  }

  handleFilterChange = (ResultValue) => {
    this.setState({ ResultValue: ResultValue }, () => {
      
    });
  }

  render() {
    const { isOpen, toggle, runnersItem, selectedItem } = this.props
    const ModalProps = {
      isOpen,
      toggle,
      size: 'md',
      className: 'custom-modal'
    }

    const {
      ResultValue,
    } = this.state

    return (
      <Modal {...ModalProps}>
        <i className="close-btn icon-cancel" onClick={toggle} />
        <ModalHeader>Result Declare</ModalHeader>
        
          <ModalBody>
            <Row>
              <Col>
                {runnersItem && <FormGroup>
                  <Label>Select Selection Name</Label>
                  <Select
                    className='react-select'
                    classNamePrefix="react-select"
                    isSearchable={false}
                    options={runnersItem}
                    value={ResultValue}
                    onChange={this.handleFilterChange}
                    getOptionLabel={selectedItem.template_name == "total" ? ({ header, name }) => header +' '+ name : ({ name }) => name }
                    getOptionValue={({ selection_id }) => selection_id}
                  />
                </FormGroup>}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" size='sm' type='submit' disabled={_.isEmpty(ResultValue) ? true : false} onClick={()=>this.onSubmit(ResultValue)}>Submit</Button>
          </ModalFooter>
        
      </Modal>
    );
  }
}

MatchResultSportsbookModal.defaultProps = {
  UserProps: {},
  isOpen: true,
  toggle: () => { }
}

export default MatchResultSportsbookModal;
