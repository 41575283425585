import React from "react";
import Loadable from "react-loadable";
import LoadingView from "Views/LoadingView";
//import {_} from 'Helpers';

function Loading(props) {
    if (props.error) {
        return <div>Error! <button onClick = {props.retry}>Retry</button></div>;
    } else if (props.timedOut) {
        return <div>Taking a long time... <button onClick = {props.retry}>Retry</button></div>;
    } else if (props.pastDelay) {
        return <LoadingView />;
    } else {
        return null;
    }
}
//let currentUser = JSON.parse(localStorage.getItem('currentUser'))






/* 
  ADMIN ROUTE 
****************/
// for dynamic routes
// let AdminRoute = []
// if(currentUser){
//     var element = {};
//     var cart = []
//     if(Number(currentUser.role) === 1){
//     cart = [
//         {
//             path          : "user/:user_guid",
//             name          : "User",
//             exact         : false,
//             isAuthenticate: true,
//             component     : Loadable({
//                 loader : () => import("./Views/User"),
//                 loading: Loading
//             })
//         },
//         {
//             path          : "betsList",
//             name          : "BetsList",
//             exact         : false,
//             isAuthenticate: true,
//             component     : Loadable({
//                 loader : () => import("./Views/BetsList"),
//                 loading: Loading
//             })
//         },
//         {
//             path          : "notification",
//             name          : "Notification",
//             exact         : false,
//             isAuthenticate: true,
//             component     : Loadable({
//                 loader : () => import("./Views/Notification"),
//                 loading: Loading
//             })
//         },
//         {
//             path          : "onlineusers",
//             name          : "OnlineUsers",
//             exact         : false,
//             isAuthenticate: true,
//             component     : Loadable({
//                 loader : () => import("./Views/OnlineUsers"),
//                 loading: Loading
//             })
//         },
//         {
//             path          : "globalsetting",
//             name          : "GlobalSetting",
//             exact         : false,
//             isAuthenticate: true,
//             component     : Loadable({
//                 loader : () => import("./Views/GlobalSetting"),
//                 loading: Loading
//             })
//         },
//         {
//             path          : "marketwatchdashboard",
//             name          : "MarketWatchDashboard",
//             exact         : false,
//             isAuthenticate: true,
//             component     : Loadable({
//                 loader : () => import("./Views/MarketWatchDashboard"),
//                 loading: Loading
//             })
//         },
//         {
//             path          : "user-account-statement",
//             name          : "UserAccountStatement",
//             exact         : false,
//             isAuthenticate: true,
//             component     : Loadable({
//                 loader : () => import("./Views/UserAccountStatement"),
//                 loading: Loading
//             })
//         }, 
//         {
//             path          : "account-statement-detail",
//             name          : "AccountStatementDetail",
//             exact         : false,
//             isAuthenticate: true,
//             component     : Loadable({
//                 loader : () => import("./Views/AccountStatementDetail"),
//                 loading: Loading
//             })
//         },
//         {
//             path          : "fixture-market",
//             name          : "FixtureMarket",
//             exact         : false,
//             isAuthenticate: true,
//             component     : Loadable({
//                 loader : () => import("./Views/FixtureMarket"),
//                 loading: Loading
//             })
//         },
//         {
//             path          : "create-group",
//             name          : "CreateGroup",
//             exact         : false,
//             isAuthenticate: true,
//             component     : Loadable({
//                 loader : () => import("./Views/CreateGroup"),
//                 loading: Loading
//             })
//         }
//     ];
//     } else{
//         cart = [
//             {
//                 path          : "user/:user_guid",
//                 name          : "User",
//                 exact         : false,
//                 isAuthenticate: true,
//                 component     : Loadable({
//                     loader : () => import("./Views/User"),
//                     loading: Loading
//                 })
//             },
//             {
//                 path          : "betsList",
//                 name          : "BetsList",
//                 exact         : false,
//                 isAuthenticate: true,
//                 component     : Loadable({
//                     loader : () => import("./Views/BetsList"),
//                     loading: Loading
//                 })
//             },
//             {
//                 path          : "marketwatchdashboard",
//                 name          : "MarketWatchDashboard",
//                 exact         : false,
//                 isAuthenticate: true,
//                 component     : Loadable({
//                     loader : () => import("./Views/MarketWatchDashboard"),
//                     loading: Loading
//                 })
//             },
//             {
//                 path          : "user-account-statement",
//                 name          : "UserAccountStatement",
//                 exact         : false,
//                 isAuthenticate: true,
//                 component     : Loadable({
//                     loader : () => import("./Views/UserAccountStatement"),
//                     loading: Loading
//                 })
//             }, 
//             {
//                 path          : "account-statement-detail",
//                 name          : "AccountStatementDetail",
//                 exact         : false,
//                 isAuthenticate: true,
//                 component     : Loadable({
//                     loader : () => import("./Views/AccountStatementDetail"),
//                     loading: Loading
//                 })
//             },
//             {
//                 path          : "fixture-market",
//                 name          : "FixtureMarket",
//                 exact         : false,
//                 isAuthenticate: true,
//                 component     : Loadable({
//                     loader : () => import("./Views/FixtureMarket"),
//                     loading: Loading
//                 })
//             },
//             {
//                 path          : "create-group",
//                 name          : "CreateGroup",
//                 exact         : false,
//                 isAuthenticate: true,
//                 component     : Loadable({
//                     loader : () => import("./Views/CreateGroup"),
//                     loading: Loading
//                 })
//             }
//         ];
//     }
    
     
    
//     _.map(currentUser.menu,(it,idx)=>{
//         if(_.isEmpty(it.submenu)){
            
//             element.exact = true;
//             element.path = it.link;
//             element.name = it.label
//             element.component= Loadable({
//                 loader : () => import("./Views/"+it.label),
//                 loading: Loading
//             })
//             cart.push(element);
//             element = {}
            
            
//         } else{
           
//             _.map(it.submenu,(is,isdx)=>{
//                 element.exact = true;
//                 element.path = is.link;
//                 element.name = is.label
//             element.component= Loadable({
//                 loader : () => import("./Views/"+is.label),
//                 loading: Loading
//             })
//                 cart.push(element);
//                 element = {}
//             })
//         }
        
//     })
//     AdminRoute = cart
//     console.log(cart,"cartcartcartcartcartcartcart")
// } else{
//      AdminRoute = [
//         {
//             path     : "guidelines",
//             name     : "Guidelines",
//             exact    : true,
//             component: Loadable({
//                 loader : () => import("./Guidelines"),
//                 loading: Loading
//             })
//         },
//         {
//             path          : "login",
//             name          : "Login",
//             exact         : true,
//             isAuthenticate: false,
//             component     : Loadable({
//                 loader : () => import("./Views/Login"),
//                 loading: Loading
//             })
//         },
//         {
//             path          : "user",
//             name          : "User",
//             exact         : false,
//             isAuthenticate: true,
//             component     : Loadable({
//                 loader : () => import("./Views/User"),
//                 loading: Loading
//             })
//         },
//         {
//             path          : "user/:user_guid",
//             name          : "User",
//             exact         : false,
//             isAuthenticate: true,
//             component     : Loadable({
//                 loader : () => import("./Views/User"),
//                 loading: Loading
//             })
//         },
//         {
//             path          : "market-watch",
//             name          : "Marketwatch",
//             exact         : false,
//             isAuthenticate: true,
//             component     : Loadable({
//                 loader : () => import("./Views/Marketwatch"),
//                 loading: Loading
//             })
//         },
//         {
//             path          : "active-matches",
//             name          : "ActiveMatches",
//             exact         : false,
//             isAuthenticate: true,
//             component     : Loadable({
//                 loader : () => import("./Views/ActiveMatches"),
//                 loading: Loading
//             })
//         },
//         {
//             path          : "reports",
//             name          : "Reports",
//             exact         : false,
//             isAuthenticate: true,
//             component     : Loadable({
//                 loader : () => import("./Views/Reports"),
//                 loading: Loading
//             })
//         },
//         {
//             path          : "reports",
//             name          : "Reports",
//             exact         : false,
//             isAuthenticate: true,
//             component     : Loadable({
//                 loader : () => import("./Views/Reports"),
//                 loading: Loading
//             })
//         },
//         {
//             path          : "sport-setting",
//             name          : "SportSetting",
//             exact         : false,
//             isAuthenticate: true,
//             component     : Loadable({
//                 loader : () => import("./Views/SportSetting"),
//                 loading: Loading
//             })
//         },
//         {
//             path          : "series-setting",
//             name          : "SeriesSetting",
//             exact         : false,
//             isAuthenticate: true,
//             component     : Loadable({
//                 loader : () => import("./Views/SeriesSetting"),
//                 loading: Loading
//             })
//         },
//         {
//             path          : "match-result",
//             name          : "MatchResult",
//             exact         : false,
//             isAuthenticate: true,
//             component     : Loadable({
//                 loader : () => import("./Views/MatchResult"),
//                 loading: Loading
//             })
//         },
//         {
//             path          : "match-rollback",
//             name          : "MatchRollback",
//             exact         : false,
//             isAuthenticate: true,
//             component     : Loadable({
//                 loader : () => import("./Views/MatchRollback"),
//                 loading: Loading
//             })
//         },
//         {
//             path          : "staff",
//             name          : "Staff",
//             exact         : false,
//             isAuthenticate: true,
//             component     : Loadable({
//                 loader : () => import("./Views/Staff"),
//                 loading: Loading
//             })
//         },
//         {
//             path          : "notification",
//             name          : "Notification",
//             exact         : false,
//             isAuthenticate: true,
//             component     : Loadable({
//                 loader : () => import("./Views/Notification"),
//                 loading: Loading
//             })
//         },
//         {
//             path          : "onlineusers",
//             name          : "OnlineUsers",
//             exact         : false,
//             isAuthenticate: true,
//             component     : Loadable({
//                 loader : () => import("./Views/OnlineUsers"),
//                 loading: Loading
//             })
//         },
//         {
//             path          : "globalsetting",
//             name          : "GlobalSetting",
//             exact         : false,
//             isAuthenticate: true,
//             component     : Loadable({
//                 loader : () => import("./Views/GlobalSetting"),
//                 loading: Loading
//             })
//         },
//         {
//             path          : "marketwatchdashboard",
//             name          : "MarketWatchDashboard",
//             exact         : false,
//             isAuthenticate: true,
//             component     : Loadable({
//                 loader : () => import("./Views/MarketWatchDashboard"),
//                 loading: Loading
//             })
//         },
//         {
//             path          : "user-account-statement",
//             name          : "UserAccountStatement",
//             exact         : false,
//             isAuthenticate: true,
//             component     : Loadable({
//                 loader : () => import("./Views/UserAccountStatement"),
//                 loading: Loading
//             })
//         }, 
//         {
//             path          : "account-statement",
//             name          : "AccounStatement",
//             exact         : false,
//             isAuthenticate: true,
//             component     : Loadable({
//                 loader : () => import("./Views/AccounStatement"),
//                 loading: Loading
//             })
//         },
//         {
//             path          : "account-statement-detail",
//             name          : "AccountStatementDetail",
//             exact         : false,
//             isAuthenticate: true,
//             component     : Loadable({
//                 loader : () => import("./Views/AccountStatementDetail"),
//                 loading: Loading
//             })
//         },
//         {
//             path          : "bet-history",
//             name          : "BetHistory",
//             exact         : false,
//             isAuthenticate: true,
//             component     : Loadable({
//                 loader : () => import("./Views/BetHistory"),
//                 loading: Loading
//             })
//         },
//         {
//             path          : "profit-loss-by-match",
//             name          : "ProfitLossMatch",
//             exact         : false,
//             isAuthenticate: true,
//             component     : Loadable({
//                 loader : () => import("./Views/ProfitLossMatch"),
//                 loading: Loading
//             })
//         },
//         {
//             path          : "profit-loss-by-downline",
//             name          : "ProfitLossDownLine",
//             exact         : false,
//             isAuthenticate: true,
//             component     : Loadable({
//                 loader : () => import("./Views/ProfitLossDownLine"),
//                 loading: Loading
//             })
//         },
//         {
//             path          : "profit-loss-by-downline/:user-id",
//             name          : "ProfitLossDownLine",
//             exact         : false,
//             isAuthenticate: true,
//             component     : Loadable({
//                 loader : () => import("./Views/ProfitLossDownLine"),
//                 loading: Loading
//             })
//         },
//         {
//             path          : "delete-bets",
//             name          : "DeleteBets",
//             exact         : false,
//             isAuthenticate: true,
//             component     : Loadable({
//                 loader : () => import("./Views/DeleteBets"),
//                 loading: Loading
//             })
//         },
//         {
//             path          : "betsList",
//             name          : "BetsList",
//             exact         : false,
//             isAuthenticate: true,
//             component     : Loadable({
//                 loader : () => import("./Views/BetsList"),
//                 loading: Loading
//             })
//         },
//         {
//             path          : "manage-series",
//             name          : "ManageSeries",
//             exact         : false,
//             isAuthenticate: true,
//             component     : Loadable({
//                 loader : () => import("./Views/ManageSeries"),
//                 loading: Loading
//             })
//         },
//         {
//             path          : "fixture-market",
//             name          : "FixtureMarket",
//             exact         : false,
//             isAuthenticate: true,
//             component     : Loadable({
//                 loader : () => import("./Views/FixtureMarket"),
//                 loading: Loading
//             })
//         },
//         {
//             path          : "manage-fancy",
//             name          : "ManageFancy",
//             exact         : false,
//             isAuthenticate: true,
//             component     : Loadable({
//                 loader : () => import("./Views/ManageFancy"),
//                 loading: Loading
//             })
//         },
//         {
//             path          : "staff-privileges-group",
//             name          : "StaffPrivilegesGroup",
//             exact         : false,
//             isAuthenticate: true,
//             component     : Loadable({
//                 loader : () => import("./Views/StaffPrivilegesGroup"),
//                 loading: Loading
//             })
//         },
//         {
//             path          : "create-group",
//             name          : "CreateGroup",
//             exact         : false,
//             isAuthenticate: true,
//             component     : Loadable({
//                 loader : () => import("./Views/CreateGroup"),
//                 loading: Loading
//             })
//         },
//     ]
// }
// for static routes
let AdminRoute = [
    {
        path     : "guidelines",
        name     : "Guidelines",
        exact    : true,
        component: Loadable({
            loader : () => import("./Guidelines"),
            loading: Loading
        })
    },
    {
        path          : "login",
        name          : "Login",
        exact         : true,
        isAuthenticate: false,
        component     : Loadable({
            loader : () => import("./Views/Login"),
            loading: Loading
        })
    },
    {
        path          : "user",
        name          : "User",
        exact         : false,
        isAuthenticate: true,
        component     : Loadable({
            loader : () => import("./Views/User"),
            loading: Loading
        })
    },
    {
        path          : "user/:user_guid",
        name          : "User",
        exact         : false,
        isAuthenticate: true,
        component     : Loadable({
            loader : () => import("./Views/User"),
            loading: Loading
        })
    },
    {
        path          : "market-watch",
        name          : "Marketwatch",
        exact         : false,
        isAuthenticate: true,
        component     : Loadable({
            loader : () => import("./Views/Marketwatch"),
            loading: Loading
        })
    },
    {
        path          : "active-matches",
        name          : "ActiveMatches",
        exact         : false,
        isAuthenticate: true,
        component     : Loadable({
            loader : () => import("./Views/ActiveMatches"),
            loading: Loading
        })
    },
    {
        path          : "reports",
        name          : "Reports",
        exact         : false,
        isAuthenticate: true,
        component     : Loadable({
            loader : () => import("./Views/Reports"),
            loading: Loading
        })
    },
    {
        path          : "reports",
        name          : "Reports",
        exact         : false,
        isAuthenticate: true,
        component     : Loadable({
            loader : () => import("./Views/Reports"),
            loading: Loading
        })
    },
    {
        path          : "sport-setting",
        name          : "SportSetting",
        exact         : false,
        isAuthenticate: true,
        component     : Loadable({
            loader : () => import("./Views/SportSetting"),
            loading: Loading
        })
    },
    {
        path          : "series-setting",
        name          : "SeriesSetting",
        exact         : false,
        isAuthenticate: true,
        component     : Loadable({
            loader : () => import("./Views/SeriesSetting"),
            loading: Loading
        })
    },
    {
        path          : "match-result",
        name          : "MatchResult",
        exact         : false,
        isAuthenticate: true,
        component     : Loadable({
            loader : () => import("./Views/MatchResult"),
            loading: Loading
        })
    },
    {
        path          : "match-rollback",
        name          : "MatchRollback",
        exact         : false,
        isAuthenticate: true,
        component     : Loadable({
            loader : () => import("./Views/MatchRollback"),
            loading: Loading
        })
    },
    {
        path          : "staff",
        name          : "Staff",
        exact         : false,
        isAuthenticate: true,
        component     : Loadable({
            loader : () => import("./Views/Staff"),
            loading: Loading
        })
    },
    {
        path          : "notification",
        name          : "Notification",
        exact         : false,
        isAuthenticate: true,
        component     : Loadable({
            loader : () => import("./Views/Notification"),
            loading: Loading
        })
    },
    {
        path          : "onlineusers",
        name          : "OnlineUsers",
        exact         : false,
        isAuthenticate: true,
        component     : Loadable({
            loader : () => import("./Views/OnlineUsers"),
            loading: Loading
        })
    },
    {
        path          : "globalsetting",
        name          : "GlobalSetting",
        exact         : false,
        isAuthenticate: true,
        component     : Loadable({
            loader : () => import("./Views/GlobalSetting"),
            loading: Loading
        })
    },
    {
        path          : "marketwatchdashboard",
        name          : "MarketWatchDashboard",
        exact         : false,
        isAuthenticate: true,
        component     : Loadable({
            loader : () => import("./Views/MarketWatchDashboard"),
            loading: Loading
        })
    },
    {
        path          : "marketwatchdashboard-casino",
        name          : "MarketWatchCasinoDashboard",
        exact         : false,
        isAuthenticate: true,
        component     : Loadable({
            loader : () => import("./Views/MarketWatchCasinoDashboard"),
            loading: Loading
        })
    },
    {
        path          : "user-account-statement",
        name          : "UserAccountStatement",
        exact         : false,
        isAuthenticate: true,
        component     : Loadable({
            loader : () => import("./Views/UserAccountStatement"),
            loading: Loading
        })
    }, 
    {
        path          : "account-statement",
        name          : "AccounStatement",
        exact         : false,
        isAuthenticate: true,
        component     : Loadable({
            loader : () => import("./Views/AccounStatement"),
            loading: Loading
        })
    },
    {
        path          : "account-statement-detail",
        name          : "AccountStatementDetail",
        exact         : false,
        isAuthenticate: true,
        component     : Loadable({
            loader : () => import("./Views/AccountStatementDetail"),
            loading: Loading
        })
    },
    {
        path          : "bet-history",
        name          : "BetHistory",
        exact         : false,
        isAuthenticate: true,
        component     : Loadable({
            loader : () => import("./Views/BetHistory"),
            loading: Loading
        })
    },
    {
        path          : "profit-loss-by-match",
        name          : "ProfitLossMatch",
        exact         : true,
        isAuthenticate: true,
        component     : Loadable({
            loader : () => import("./Views/ProfitLossMatch"),
            loading: Loading
        })
    },
    {
        path          : "profit-loss-by-match/bets-detail",
        name          : "ProfitLossMatchBetsDetail",
        exact         : true,
        isAuthenticate: true,
        component     : Loadable({
            loader : () => import("./Views/ProfitLossByMatchBetDetail"),
            loading: Loading
        })
    },
    {
        path          : "profit-loss-by-downline",
        name          : "ProfitLossDownLine",
        exact         : false,
        isAuthenticate: true,
        component     : Loadable({
            loader : () => import("./Views/ProfitLossDownLine"),
            loading: Loading
        })
    },
    {
        path          : "profit-loss-by-downline/:user-id",
        name          : "ProfitLossDownLine",
        exact         : false,
        isAuthenticate: true,
        component     : Loadable({
            loader : () => import("./Views/ProfitLossDownLine"),
            loading: Loading
        })
    },
    {
        path          : "delete-bets",
        name          : "DeleteBets",
        exact         : false,
        isAuthenticate: true,
        component     : Loadable({
            loader : () => import("./Views/DeleteBets"),
            loading: Loading
        })
    },
    {
        path          : "betsList",
        name          : "BetsList",
        exact         : false,
        isAuthenticate: true,
        component     : Loadable({
            loader : () => import("./Views/BetsList"),
            loading: Loading
        })
    },
    {
        path          : "manage-series",
        name          : "ManageSeries",
        exact         : false,
        isAuthenticate: true,
        component     : Loadable({
            loader : () => import("./Views/ManageSeries"),
            loading: Loading
        })
    },
    {
        path          : "fixture-market",
        name          : "FixtureMarket",
        exact         : false,
        isAuthenticate: true,
        component     : Loadable({
            loader : () => import("./Views/FixtureMarket"),
            loading: Loading
        })
    },
    {
        path          : "manage-fancy",
        name          : "ManageFancy",
        exact         : false,
        isAuthenticate: true,
        component     : Loadable({
            loader : () => import("./Views/ManageFancy"),
            loading: Loading
        })
    },
    {
        path          : "staff-privileges-group",
        name          : "StaffPrivilegesGroup",
        exact         : false,
        isAuthenticate: true,
        component     : Loadable({
            loader : () => import("./Views/StaffPrivilegesGroup"),
            loading: Loading
        })
    },
    {
        path          : "create-group",
        name          : "CreateGroup",
        exact         : false,
        isAuthenticate: true,
        component     : Loadable({
            loader : () => import("./Views/CreateGroup"),
            loading: Loading
        })
    },
    {
        path          : "delete-series",
        name          : "DeleteSeries",
        exact         : false,
        isAuthenticate: true,
        component     : Loadable({
            loader : () => import("./Views/DeleteSeries"),
            loading: Loading
        })
    },
    {
        path          : "feed-setting",
        name          : "FeedSetting",
        exact         : false,
        isAuthenticate: true,
        component     : Loadable({
            loader : () => import("./Views/FeedConfiguration"),
            loading: Loading
        })
    },
    {
        path          : "team-list",
        name          : "Teams",
        exact         : false,
        isAuthenticate: true,
        component     : Loadable({
            loader : () => import("./Views/Teams/Teams"),
            loading: Loading
        })
    },
    {
        path          : "banner-type",
        name          : "BannerType",
        exact         : false,
        isAuthenticate: true,
        component     : Loadable({
            loader : () => import("./Views/Banner/BannerType"),
            loading: Loading
        })
    },
    {
        path          : "banner-list",
        name          : "BannerList",
        exact         : false,
        isAuthenticate: true,
        component     : Loadable({
            loader : () => import("./Views/Banner/BannerList"),
            loading: Loading
        })
    },
    {
        path          : "bet-template",
        name          : "BetTemplate",
        exact         : false,
        isAuthenticate: true,
        component     : Loadable({
            loader : () => import("./Views/BetTemplate/BetTemplate"),
            loading: Loading
        })
    },
    {
        path          : "book_mark",
        name          : "BookMark Log",
        exact         : false,
        isAuthenticate: true,
        component     : Loadable({
            loader : () => import("./Views/BookMarkLogs/BookMark"),
            loading: Loading
        })
    }
       
]
const DefaultAdminRoute = {
    path: "user",
}

export default {
    AdminRoute, 
    DefaultAdminRoute
}