/* eslint-disable jsx-a11y/anchor-is-valid, eqeqeq */
import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, Row, Col, FormGroup, Input, Label } from 'reactstrap';

import { Utils, Http, API } from 'Helpers';

class SportSettingModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posting: false,
      formValid: false,
      min_odd_limit: this.props.item.min_odd_limit,
      max_odd_limit: this.props.item.max_odd_limit,
    }
  }


  handleChange = (e, type) => {
    const name = !type ? e.target.name : type;
    let value = !type ? e.target.value : e;
    this.setState({ [name]: value }, () => {
      this.validateForm()
    });
  };

 


  validateForm() {
    const {
      min_odd_limit,
      max_odd_limit,
     
    } = this.state;
    this.setState({
      formValid:
      min_odd_limit != ''
        && max_odd_limit != ''
        && Utils.isInvalid("max_odd_limit", max_odd_limit) == false
        && Utils.isInvalid("min_odd_limit", min_odd_limit) == false
        && (Number(min_odd_limit) <= Number(max_odd_limit)) ? true : false
        
        
       
      },()=>{
        
      });
  }





  onSubmit = (e) => {
    e.preventDefault();
    this.setState({ posting: true })
    
    const {
      min_odd_limit,
      max_odd_limit,
     
    } = this.state;

    let param = {
      "sports_id" : this.props.item.sports_id,
      "max_odd_limit": max_odd_limit,
      "min_odd_limit" : min_odd_limit
     
    }

     Http.post(API.SAVE_SPORTS_SETTING, param).then(response => {
      this.setState({ posting: false },this.props.callBack)
      Utils.notify(response.message);
    }).catch(error => {
      this.setState({ posting: false })
    }); 
  }

  render() {
    const { isOpen, toggle } = this.props
    const ModalProps = {
      isOpen,
      toggle,
      size: 'md',
      className: 'custom-modal'
    }
    const {
      posting,
      formValid,
      min_odd_limit,
      max_odd_limit,
    
    } = this.state

    return (
      <Modal {...ModalProps}>
        <i className="close-btn icon-cancel" onClick={toggle} />
        <ModalHeader>Sport Setting</ModalHeader>
        <Form onSubmit={this.onSubmit} >
          <ModalBody>
            <Row>
              <Col xs={12} md={6}>
                <FormGroup>
                  <Label>Min Stake Limit</Label>
                  <Input
                    type="text"
                    name="min_odd_limit"
                    value={min_odd_limit}
                    onChange={this.handleChange}
                    autoComplete="off"
                    invalid={Utils.isInvalid("min_odd_limit", min_odd_limit)}
                  />
                </FormGroup>
              </Col>
              <Col  xs={12} md={6}>
                <FormGroup>
                  <Label>Max Stake Limit</Label>
                  <Input
                    id='max_odd_limit'
                    type="text"
                    name="max_odd_limit"
                    value={max_odd_limit}
                    onChange={this.handleChange}
                    autoComplete="off"
                    invalid={Utils.isInvalid("max_odd_limit", max_odd_limit)}
                    />
                </FormGroup>
              </Col>
              
            </Row>
           
           


          </ModalBody>
          <ModalFooter>
            <Button color="primary" size='sm' type='submit' disabled={!formValid || posting}>Change</Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}

SportSettingModal.defaultProps = {
  UserProps: {},
  isOpen: true,
  toggle: () => { }
}

export default SportSettingModal;
