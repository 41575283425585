const CONSTANT =  {
  DEVICE_TYPE: 3,
  DEVICE_ID: "",
  ITEMS_PERPAGE_XL:50,
  ITEMS_PERPAGE: 20,
  ITEMS_PERPAGE_SM: 10,
  ITEMS_PERPAGE_XS: 5,
  CURRENT_OFFSET: 0,
  CURRENT_PAGE: 1,
  GET_BOOKMAKER_TYPE: {
    1: 'Sportsbook',
    2: 'Exchange',
    3: 'Fancy',
    4: 'Live Cards',
    5: 'Live Casino',
    6: 'Bookmaker'
  },
  TIMEZONE: "",
  MAX_REFERENCE_VALUE:9999999999
}

export default CONSTANT;
