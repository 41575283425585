/* eslint-disable eqeqeq, no-useless-escape */

import validator from "validator";

// console.log(validator)
class Validation {
  static init(type, value) {
    let isValidate = null;
    if (value != '' && value != null && (value.length >= 0 || Date.parse(value))) {
      // console.log(value)
      switch (type) {
        case "required":
          isValidate = validator.isLength(value, { min: 3, max: 50 })
            ? false
            : true;
          break;
        case "number":
          isValidate = validator.isNumeric(value)
            ? false
            : true;
          break;
        case "addNotification":
          isValidate = (validator.isLength(value, { min: 10, max: 250 }) && value.match(/^[^ ].*$/)) ? false : true;
          break;
        
          case "odds_stack":
          isValidate = (value.match(/^(?:[1-9]|[1-9][0-9]{1,3})(?:,(?:[1-9]|[1-9][0-9]{1,3}))*$/)) ? false : true;
          break;

        case "fancy_stack":
          isValidate = (value.match(/^(?:[1-9]|[1-9][0-9]{1,3})(?:,(?:[1-9]|[1-9][0-9]{1,3}))*$/)) ? false : true;
          break;
          
        case "casino_stack":
          isValidate = (value.match(/^(?:[1-9]|[1-9][0-9]{1,3})(?:,(?:[1-9]|[1-9][0-9]{1,3}))*$/)) ? false : true;
          break;

        case "address":
        case "address2":
          isValidate = !validator.isLength(value, { min: 3, max: 250 }) ? true : false;
          break;
        case "amount":
          isValidate = validator.isEmpty(value) ? true : false;
          break;

        case "aptnumber":
          isValidate = validator.isEmpty(value) ? true : false;
          break;

        case "zipcode":
        case "zip_code":
          isValidate = validator.isEmpty(value) ? true : false;
          break;

        case "city":
          isValidate = validator.isEmpty(value) ? true : false;
          break;

        case "phonenumber":
          isValidate = validator.isEmpty(value) ? true : false;
          break;

        case "ssnnumber":
          isValidate = value.length == 4 && value.match(/[^a-z ]\ *([.0-9])*\d/g) ? false : true;
          break;
        
        case "min_odd_limit":
          isValidate =  value.match(/^^(?:[1-9][0-9]{4}|[1-9][0-9]{3}|[1-9][0-9]{2}|[1-9][0-9]|[1-9])$/g) ? false : true;
          break;
          
        case "max_odd_limit":
          isValidate =  value.match(/^^(?:[1-9][0-9]{4}|[1-9][0-9]{3}|[1-9][0-9]{2}|[1-9][0-9]|[1-9])$/g) ? false : true;
          break;

        case "user_name":
          isValidate = validator.blacklist(value, '_.-') && validator.isLength(value, { min: 3, max: 50 }) && (value.match(/^[a-zA-Z]*[a-zA-Z0-9]*[a-zA-Z0-9_.-]*$/) || (validator.isAlphanumeric(value, 'ar') || validator.isAlphanumeric(value))) ? false : true;
          break;

        case "date":
        case "dob":
          var someDate = Date.parse(value);
          isValidate = isNaN(someDate) ? true : false;
          break;

        case "email":
        case "Email":
          isValidate = validator.isEmail(value) ? false : true;
          break;

        case "password":
        case "old_password":
        isValidate = value.length >= 8 && value.length <= 16 ? false : true;
        break;
        case "Password":
          // isValidate = value.match(/(?=^.{8,}$)(?=(?:.*?\d){1})(?=.*[a-zA-z])(?!.*\s)[0-9a-zA-Z!@#$%*()_+^&]*/g)
          //   ? false
          //   : true;
            var re = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])[ A-Za-z0-9_@./#&+-]{8,16}$/;
            let alpha=/^[a-zA-Z]*$/
            //var re = /^[A-Z][A-Za-z0-9_-]{3,19}$/
             if(value.length>0){
               if(!alpha.test(value.charAt(0)))
               {
                 console.log('isDigit')
                 isValidate= true;
               } else if(value.charAt(0)!=value.charAt(0).toUpperCase()){
                console.log('toUpperCase')
                isValidate= true;
              }
               else if(!re.test(value)){
                isValidate= true;
              }
               else{
                isValidate= false;
              }
             }else{
               return;
             }
          
          break;
        case "FirstName":
        case "LastName":
        case "first_name":
        case "last_name":
        case "name":
          isValidate =
            validator.isLength(value, { min: 3, max: 100 }) &&
              value.match(/(([A-Za-z'-]+)?)$/)
              ? false
              : true;
          break;
        case "team_name":
          isValidate = value.length >= 4 ? false : true;
          break;
        case "game_name":
          isValidate = (validator.isLength(value, { min: 3, max: 50 }))
            ? false
            : true;
          break;
        case "username":
          isValidate = (validator.isLength(value, { min: 3, max: 50 }) && value.match(/^[a-zA-Z]*[a-zA-Z0-9]*[a-zA-Z0-9_.-]*$/))
            ? false
            : true;
          break;
        case "phone_no":
        case "mobile":
          isValidate = value.match(
            /^(?:(\+)?([0-9]{1,3})?[-.● ]?)?\(?([0-9]{3})\)?[-.● ]?([0-9]{3})[-.● ]?([0-9]{4})$/
          )
            ? false
            : true;
          break;
        default:
          break;
      }
    } else {
      isValidate = false
    }
    return isValidate;
  } 
}

export default Validation;


/* 
  First name – Max character limit 100
  Last name – Max character limit 100
  Username – Min-Max character limit 3-50
  Street address – Max character limit 250
*/