/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, Row, Col, FormGroup, Input, Label } from 'reactstrap';
import { Utils, Http, API } from 'Helpers';
import {CONSTANT} from 'Constants';


class UpdateReferenceModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reference: '',
            formValid: false,
            posting: false,
            max_reference_value:CONSTANT.MAX_REFERENCE_VALUE
        }
    }


    handleChange = (e) => {

        this.setState({ [e.target.name]: e.target.value }, () => {
            this.validateForm()
        });
    };

    validateForm() {
        const {
            reference,
            max_reference_value
        } = this.state;
        this.setState({
            formValid:
                reference != ''
                && Utils.isInvalid("number", reference) == false
                && reference >= 0
                && reference <= max_reference_value
        });
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.setState({ posting: true })
        const { UserProps } = this.props
        const {
            reference
        } = this.state;

        let param = {
            reference,
            user_guid: UserProps.user_guid,
        }

        Http.post(API.UPDATE_REFERENE, param).then(response => {
            this.setState({ posting: false }, this.props.callBack)
            Utils.notify(response.message);
        }).catch(error => {
            this.setState({ posting: false })
        });
    }


    componentDidMount() {
        this.setState({ reference: this.props.UserProps.reference })
    }


    render() {
        const { isOpen, toggle, UserProps } = this.props
        const ModalProps = {
            isOpen,
            toggle,
            size: 'sm',
            className: 'custom-modal'
        }
        const {
            reference, formValid, posting, max_reference_value
        } = this.state;

        console.log(formValid)


        return (
            <Modal {...ModalProps}>
                <i className="close-btn icon-cancel" onClick={toggle} />
                <ModalHeader>Update Reference</ModalHeader>
                <Form onSubmit={this.onSubmit} >
                    <ModalBody>
                        <Row>
                            <Col className={'mb-4 text-center'}><h5>{UserProps.name}</h5></Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={12}>
                                <FormGroup>
                                    <Label>Reference</Label>
                                    <Input
                                        type="text"
                                        name="reference"
                                        value={reference}
                                        onChange={this.handleChange}
                                        autoComplete="off"
                                        invalid={Utils.isInvalid("number", reference) || reference < 0 || reference > max_reference_value}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" size='sm' type='submit' disabled={!formValid || posting}>Save</Button>
                    </ModalFooter>
                </Form>
            </Modal>
        )

    }

}

export default UpdateReferenceModal;