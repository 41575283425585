/* eslint-disable jsx-a11y/anchor-is-valid, eqeqeq */
import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, Form, Row, Col, Container, Table, Input, Button } from 'reactstrap';
import { _, Http, API, Utils } from 'Helpers';
class UserwiseModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posting: false,
      ChildUserList: this.props.ChildUserList,
      parent_id: '',
    }
  }
  changeStatus = (item, idx) => {
    const { event_id } = this.props
    let param = {
      user_guid: item.user_guid,
      event_id: event_id,
      lock_betting: item.lock_betting == 1 ? 0 : 1
    }

    this.setState({ posting: true })
    Http.post(API.LOCK_EVENT_BET, param).then(response => {
      let tempArr = this.state.ChildUserList
      tempArr[idx].lock_betting = item.lock_betting == 1 ? 0 : 1;
      //this.props.ChildUserList = tempArr
      this.setState({
        posting: false,
        ChildUserList: tempArr
      })
      Utils.notify(response.message);
    }).catch(error => {
      this.setState({ posting: false })
    });
  }

  changeStatusFancy = (item, idx) => {
    const { event_id } = this.props
    let param = {
      user_guid: item.user_guid,
      event_id: event_id,
      lock_fancy_betting: item.lock_fancy_betting == 1 ? 0 : 1
    }

    this.setState({ posting: true })
    Http.post(API.LOCK_EVENT_FANCYBET, param).then(response => {
      let tempArr = this.state.ChildUserList
      tempArr[idx].lock_fancy_betting = item.lock_fancy_betting == 1 ? 0 : 1;
      //this.props.ChildUserList = tempArr
      this.setState({
        posting: false,
        ChildUserList: tempArr
      })
      Utils.notify(response.message);
    }).catch(error => {
      this.setState({ posting: false })
    });
  }

  getChild = (user_id) => {
    if(this.state.posting)return;
    const { event_id } = this.props
    let param = {
      event_id: event_id,
      user_id: user_id
    }

    this.setState({ posting: true })
    Http.post(API.GET_CHILD_USERS, param)
      .then(response => {
        this.setState({
          ChildUserList: response.data.data,
          parent_id: response.data.parent_id,
          posting: false
        }, () => {
        })
      }).catch(error => {
        this.setState({ posting: false })
      });
  }


  totalCapChange = (e, idx) => {
    let ChildUserList = JSON.parse(JSON.stringify(this.state.ChildUserList));
    ChildUserList[idx]['lock_total_cap'] = e.target.value;
    ChildUserList[idx]['total_caps_valid'] = e.target.value != "" ? Utils.isValid("number", e.target.value) : true;
    this.setState({ ChildUserList })
  }


  onTotalCapsSubmitHandler = (item) => {
    const { event_id } = this.props
    let param = {
      event_id: event_id,
      user_id: item.user_id,
      lock_total_cap:item.lock_total_cap,
      parent_id:item.parent_id
    }
    this.setState({ posting: true })
    Http.post(API.UPDATE_USERWISE_TOTAL_CAPS, param)
      .then(response => {
        this.setState({ posting: false })
        Utils.notify(response.message);
      })
      .catch(error => {
        this.setState({ posting: false })
      });
  }


  render() {
    const { isOpen, toggle, SelectedBettype } = this.props
    const { ChildUserList, parent_id, posting } = this.state
    const ModalProps = {
      isOpen,
      toggle,
      size: 'md',
      className: 'custom-modal'
    }
    return (
      <Modal {...ModalProps}>
        <i className="close-btn icon-cancel" onClick={toggle} />
        <ModalHeader>Userwise</ModalHeader>
        <Form>

          <ModalBody className="p-0">
            <Container fluid className="p-0">
              {
                posting &&
                <Utils.Spinner className='position-absolute' />
              }
              <Row>
                <Col>
                  <div className="white-container">
                    <Row>
                      <Col>
                        {(parent_id  && parent_id != "0") &&
                          <div className="btn-xs-action-bet with-user-head">
                            <a onClick={() => this.getChild(parent_id)}>Back</a>
                          </div>

                        }
                        <div className="uservise-container">
                          <Table hover className='m-0 table-primary'>

                            <thead>

                              <tr className='text-uppercase'>
                                <th style={{ width: `5%` }}>S.NO.</th>
                                <th>Name</th>
                                <th>User Id</th>
                                <th style={{ width: `10%` }} className="text-center">{SelectedBettype == 'totalcapsLock'  ?'Action':'Active'}</th>
                              </tr>
                            </thead>

                            <tbody>
                              {_.map(ChildUserList, (item, key) => {
                                return (
                                  <tr {...{ key }}>
                                    <td><span>{key + 1}</span></td>
                                    <td><span className={Number(item.role) == 4 ? '' : 'pointer'} onClick={() => Number(item.role) == 4 ? '' : this.getChild(item.user_id)}>{item.name}</span></td>
                                    <td><span>{item.username}</span></td>
                                    <td className="btn-xs-action-bet with-user">
                                      {SelectedBettype == 'betlock' ?
                                        <a className={item.lock_betting == '0' ? 'active' : 'inactive'} onClick={() => this.changeStatus(item, key)}>{item.lock_betting == '0' ? 'Active' : 'Inactive'}</a>
                                        :
                                        SelectedBettype == 'fancylock' ?
                                          <a className={item.lock_fancy_betting == '0' ? 'active' : 'inactive'} onClick={() => this.changeStatusFancy(item, key)}>{item.lock_fancy_betting == '0' ? 'Active' : 'Inactive'}</a>
                                          :
                                          SelectedBettype == 'totalcapsLock' ?
                                            <React.Fragment>
                                              <div className={'d-flex capslock-btn-grp'}>
                                                <Input className={'text-center'} style={{ "width": 80 }} value={item.lock_total_cap || ''} onChange={(e) => this.totalCapChange(e, key)} />
                                                <Button color="primary" disabled={(!item.total_caps_valid) || posting} style={{ fontSize: 11 }} size="sm" onClick={() => this.onTotalCapsSubmitHandler(item)}>Save</Button>
                                              </div>
                                            </React.Fragment>
                                            : ''
                                      }
                                    </td>


                                  </tr>
                                )

                              })
                              }
                            </tbody>
                          </Table>

                        </div>

                        {/* {SelectedBettype == 'totalcapsLock' ? <div className={'py-3 text-center'}><Button color="primary" size="sm" onClick={this.onTotalCapsSubmitHandler}>Save</Button></div> : ''} */}
                      </Col>
                    </Row>

                  </div>
                </Col>
              </Row>

            </Container>

          </ModalBody>


        </Form>
      </Modal>
    );
  }
}

export default UserwiseModel;
