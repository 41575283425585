/* eslint-disable jsx-a11y/anchor-is-valid, eqeqeq */
import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, FormGroup, Input } from 'reactstrap';

import { _, Http, API, Utils } from 'Helpers';

class FancyResultModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posting: false,
      formValid: false,
      resultVal: '',

    }
  }

  handleChange = (e, type) => {
    const re = /^[0-9\b]+$/;
    let value = !type ? e.target.value : e;

    if (value === '') {
       this.setState({ resultVal: value });
    } else if (re.test(value)) {
      
      this.setState({ resultVal: value });
     
    } else {
      
    }
  };

  UpdateResult = () => {
    let param = {
      session_id: this.props.SelectedItem.session_id,
      result: this.state.resultVal,
    }

    this.setState({ posting: true })
    Http.post(API.UPDATE_FANCY_RESULT, param).then(response => {
      Utils.notify(response.message);
      this.setState({ posting: false })
      this.props.callback()
    }).catch(error => {
      this.setState({ posting: false })
    });

  }

  render() {
    const { isOpen, toggle } = this.props
    const ModalProps = {
      isOpen,
      toggle,
      size: 'sm',
      className: 'custom-modal'
    }
    const {resultVal} = this.state
    return (
      <Modal {...ModalProps}>
        <i className="close-btn icon-cancel" onClick={toggle} />
        <ModalHeader>Result Declare</ModalHeader>

        <ModalBody>
          <Row>
            <Col>
              <FormGroup className='mng-fnc-form-group'>
                <Input
                  type="text"
                  name='resultVal'
                  id="resultVal"
                  value={resultVal}
                  maxLength={6}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" size='sm' type='submit' disabled={_.isEmpty(resultVal) ? true : false} onClick={() => this.UpdateResult(resultVal)}>Submit</Button>
        </ModalFooter>

      </Modal>
    )
  }
}

export default FancyResultModal;
