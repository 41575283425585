import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { Utils } from 'Helpers'
import { Trans } from 'react-i18next';

class LoadingView extends Component {
  render() {
    return (
      <div className='page-loading'>
        <Utils.Spinner />
        <div className='page-loading-title'><Trans>Loading...</Trans></div>
      </div>
    )
  }
}
export default withRouter(LoadingView, { withRef: true })