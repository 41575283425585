import { BehaviorSubject } from 'rxjs';
import { Utils, API, Auth, Http } from 'Helpers';
import { notify } from 'react-notify-toast';


const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

function login(username, password) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      username: username,
      password: password
    })
  };

  return fetch(API.LOGIN, requestOptions)
    .then(Utils.HandleResponse)
    .then(user => {
      console.log(user)
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      Auth.setAuth(user.data.Sessionkey)

      // localStorage.setItem('currentUser', JSON.stringify(user.data.user_profile));
      // currentUserSubject.next(user.data.user_profile);

      localStorage.setItem('currentUser', JSON.stringify(user.data));
      currentUserSubject.next(user.data);

      return user;
    }
      ,
      error => {
        console.log(error)
        notify.show(error, "error", 2500);
        return error;

      }

    );
}

function logout() {
  // remove user from local storage to log user out
  let param = {
    Sessionkey: Auth.getAuth()
  }
  Http.post(API.LOGOUT, param).then(response => {
    localStorage.clear();
    window.location.reload();
    this.props.history.push('/login')
    currentUserSubject.next(null);

  }).catch(error => {

  });
}

export default {
  login,
  logout,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() { return currentUserSubject.value }
}