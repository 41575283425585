/* eslint-disable jsx-a11y/anchor-is-valid, eqeqeq */
import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, FormGroup, Label } from 'reactstrap';
import Select from 'react-select';
import { _, Http, API, Utils } from 'Helpers';

class MatchResultModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posting: false,
      formValid: false,
      ResultValue: '',

    }
  }


  onSubmit = (data) => {
    const { selectedItem, activeBookmaker } = this.props
    
    
    let param = {
      "market_event_id": activeBookmaker ?  data.bookmaker_id : selectedItem.market_event_id,
      "selection_id": activeBookmaker ? data.selection_id : data.selectionId
    }
    Http.post(activeBookmaker ? API.UPDATE_SETTLEMENT_BOOKMAKER_RESULT :  API.UPDATE_SETTLEMENT_RESULT, param).then(response => {
      Utils.notify(response.message);
      this.props.toggle()
      this.props.callback()
    }).catch(error => {
      this.setState({ posting: false })
    });

  }

  handleFilterChange = (ResultValue) => {
    this.setState({ ResultValue: ResultValue }, () => {

    });
  }

  render() {
    const { isOpen, toggle, runnersItem, activeBookmaker } = this.props
    const ModalProps = {
      isOpen,
      toggle,
      size: 'md',
      className: 'custom-modal'
    }

    const {
      ResultValue,
    } = this.state

    return (
      <Modal {...ModalProps}>
        <i className="close-btn icon-cancel" onClick={toggle} />
        <ModalHeader>Result Declare</ModalHeader>
        
          <ModalBody>
            <Row>
              <Col>
                {runnersItem && <FormGroup>
                  <Label>Select Selection Name</Label>
                  <Select
                    className='react-select'
                    classNamePrefix="react-select"
                    isSearchable={false}
                    options={runnersItem}
                    value={ResultValue}
                    onChange={this.handleFilterChange}
                    getOptionLabel={ activeBookmaker ? ({ name }) =>  name : ({ runnerName }) =>  runnerName}
                    getOptionValue={({ selectionId }) => selectionId}
                  />
                </FormGroup>}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" size='sm' type='submit' disabled={_.isEmpty(ResultValue) ? true : false} onClick={()=>this.onSubmit(ResultValue)}>Submit</Button>
          </ModalFooter>
        
      </Modal>
    );
  }
}

MatchResultModal.defaultProps = {
  UserProps: {},
  isOpen: true,
  toggle: () => { }
}

export default MatchResultModal;
