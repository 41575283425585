/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import { Form, FormGroup, Input, Button } from 'reactstrap';
import { Auth } from 'Service';
import { Utils, _ } from 'Helpers';

class LoginComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      posting: false,
      formValid: false,
      username: "",
      password: "",
    }
    if (Auth.currentUserValue) {
      this.props.history.push('/');
    }
  }


  handleChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (name === 'password') {
      value = value.trim()
    }
    this.setState({ [name]: value }, this.validateForm);
  };

  validateForm() {
    const { password, username } = this.state
    this.setState({
      formValid:
        password != '' &&
        username != '' &&
        Utils.isFormValid(['password', 'username'], this.state)
    });
  }

  componentDidMount() {
    if (Utils.isDev()) {
      this.setState({
        username: "admin",
        password: "12345678"
      }, this.validateForm)
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({ posting: true });
    const { username, password } = this.state;

    Auth.login(username, password)
      .then(
        response => {
             if(response.response_code==200)
             {
             let check =  _.find(response.data.menu, function(o) { return o.link == 'market-watch'; });
              if(!_.isUndefined(check) && check.link == 'market-watch'){
                this.props.history.push('/'+check.link)
                localStorage.setItem('firstlogin', response.data.first_time_login);
                window.location.reload();
              } else{
                let urlsd = _.isEmpty(Auth.currentUserValue.menu[0].submenu) ? Auth.currentUserValue.menu[0].link : Auth.currentUserValue.menu[0].submenu[0].link
                this.props.history.push('/'+urlsd)
              }
              
               
              
              
             }
            
            else{
              this.setState({posting:false})
               this.validateForm();
            }
        },
        error => {
          console.log(error)
        }
      );

  }

  render() {
    const {
      username,
      password,
      formValid,
      posting,
    } = this.state;
    return (
      <div className="login-container">
        
        <div className="title">Log In</div>
        <Form onSubmit={this.onSubmit}>
          <FormGroup className='input-border-bottom' >
            <span className='label'>Username</span>
            <Input type="text"
              id='username'
              name='username'
              required
              value={username}
              autoComplete="off"
              onChange={this.handleChange}
              invalid={Utils.isInvalid("username", username)}
              valid={Utils.isValid("username", username)} />
          </FormGroup>
          <FormGroup className='input-border-bottom m-0' >
            <span className='label'>Password</span>
            <Input
              id='password'
              name='password'
              required
              type='password'
              maxLength={50}
              value={password}
              autoComplete="off"
              onChange={this.handleChange}
              invalid={Utils.isInvalid("password", password)}
              valid={Utils.isValid("password", password)} />
          </FormGroup>
          <FormGroup className='m-0'>
            <Button disabled={!formValid || posting} type='submit' size="lg" color="primary" className='round' block>Login</Button>
          </FormGroup>
        </Form>
      </div>
    );
  }
}

export default LoginComponent;
