/* eslint-disable jsx-a11y/anchor-is-valid, eqeqeq */
import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, Row, Col, FormGroup, Input, Label } from 'reactstrap';
import { Utils, Http, API } from 'Helpers';
import Role from 'Helpers/Role/Role';
import { Auth } from 'Service';
import { Images } from 'Constants';

class UserPointInOutModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posting: false,
      formValid: false,
      name: '',
      username: '',
      password: '',
      confirm_password: '',
      parent_guid: '',
      opening_balance: '',
      opening_date: null,
      role: '',
      remark: '',
      currentUser: Auth.currentUserValue,
      direct_debit: '0'
    }
  }

  validateForm() {
    const {
      amount,
    } = this.state;
    this.setState({
      formValid:
        amount !== ''
    });
  }



  onSubmit(type) {
    this.setState({ posting: true })
    const { UserProps } = this.props

    const {
      amount,
      remark,
      direct_debit,
      currentUser
    } = this.state;

    let param = {
      user_guid: UserProps.user_guid,
      amount,
      remark,
      type
    }

    if (direct_debit == '1' && UserProps.role == Role.Punter && (currentUser.role == Role.SuperAdmin || currentUser.role == Role.Staff)) {
      console.log('check')
      param['direct_debit'] = direct_debit
    }

    Http.post(API.BALANCE_IN_OUT, param).then(response => {
      this.setState({ posting: false }, this.props.callBack)
    }).catch(error => {
      this.setState({ posting: false })
    });
  }
  handleChange = (e, type) => {
    const name = !type ? e.target.name : type;
    let value = !type ? e.target.value : e;
    this.setState({ [name]: value }, () => {
      this.validateForm()
    });
  };

  checkboxHandler = key => {
    this.setState({ [key]: this.state[key] == '0' ? '1' : '0' })
  }
  render() {
    const { isOpen, toggle, ParentUserProps, UserProps } = this.props
    const ModalProps = {
      isOpen,
      toggle,
      size: 'md',
      className: 'custom-modal'
    }

    const {
      amount,
      remark,
      formValid,
      posting,
      currentUser,
      direct_debit
    } = this.state;
    return (
      <Modal {...ModalProps}>
        <i className="close-btn icon-cancel" onClick={toggle} />
        <ModalHeader>A/C Points In/Out</ModalHeader>
        <Form>
          <ModalBody>
            
            <Row>
              {ParentUserProps ? <Label className="bold-label">
                Parent Balance: {ParentUserProps.balance ? parseFloat(ParentUserProps.balance).toFixed(2) : '-'} 
                || User Balance: {UserProps.balance ? parseFloat(UserProps.balance).toFixed(2) : '-'}
              </Label>
                : <Label className="bold-label">User Balance: {UserProps.balance ? parseFloat(UserProps.balance).toFixed(2) : '-'}</Label>}
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <FormGroup>
                  <Label>Points</Label>
                  <Input type="text"
                    name="amount"
                    value={amount}
                    onChange={this.handleChange}
                    autoComplete="off"
                    invalid={Utils.isInvalid("number", amount)} />

                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <FormGroup>
                  <Label>Remark</Label>
                  <Input type="textarea"
                    name="remark"
                    value={remark}
                    onChange={this.handleChange}
                    autoComplete="off"
                    invalid={Utils.isInvalid("required", remark)} />
                </FormGroup>
              </Col>
            </Row>

            {
              UserProps.role == Role.Punter && (currentUser.role == Role.SuperAdmin || currentUser.role == Role.Staff) ?
                <Row>
                  <Col xs={12}>
                    <FormGroup>

                      <div className="checkbox-container dw-checker">
                        <span onClick={() => this.checkboxHandler('direct_debit')}>
                          <img alt="" className="checkbox-img" src={direct_debit == 1 ? Images.CHECKED : Images.UNCHECKED} />
                          <span className="header">Direct Debit by Superadmin</span>
                        </span>

                      </div>

                    </FormGroup>
                  </Col>
                </Row> : ''
            }




          </ModalBody>
          <ModalFooter>
            <Button color="primary" size='sm' onClick={() => this.onSubmit(1)} disabled={!formValid || posting || direct_debit == '1'} >Deposit</Button>
            <Button color="primary" size='sm' onClick={() => this.onSubmit(2)} disabled={!formValid || posting}>Withdrawal</Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}

UserPointInOutModal.defaultProps = {
  isOpen: true,
  toggle: () => { }
}
export default UserPointInOutModal;
