/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, Row, Col, FormGroup, Input, Label } from 'reactstrap';
import { Utils, Http, API } from 'Helpers';


class TotalCapsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            total_cap: '',
            formValid: false,
            posting: false,
        }
    }


    handleChange = (e) => {

        this.setState({ [e.target.name]: e.target.value }, () => {
            this.validateForm()
        });
    };

    validateForm() {
        const {
            total_cap
        } = this.state;
        this.setState({
            formValid:
                // total_cap != '' && 
                Utils.isInvalid("number", total_cap) == false
        });
    }

    onSubmit = (e) => {
        e.preventDefault();

        if(!this.state.formValid)return;


        this.setState({ posting: true })
        const { UserProps } = this.props
        const {
            total_cap
        } = this.state;


        let param = {
            event_id:UserProps.event_id,
            total_cap: total_cap
        }

        let api = '';
        if (UserProps.activeTab == '1') {
            api = API.UPDATE_TOTAL_CAP;
            param['event_id'] = UserProps.event_id
        }
        if (UserProps.activeTab == '2') {
            api = API.UPDATE_TOTAL_CAP_SB;
            param['event_id'] = UserProps.event_id
        }
        if (UserProps.activeTab == '3') {
            api = API.UPDATE_TOTAL_CAP_CS;
            param['league_uid'] = UserProps.league_uid
        }

        if (api == '') {
            return;
        }

        Http.post(api, param).then(response => {
            this.setState({ posting: false }, ()=>this.props.callBack(total_cap))
            Utils.notify(response.message);
        }).catch(error => {
            this.setState({ posting: false })
        });
    }


    componentDidMount() {
        this.setState({ total_cap: this.props.UserProps.total_cap })
    }


    render() {
        const { isOpen, toggle } = this.props
        const ModalProps = {
            isOpen,
            toggle,
            size: 'sm',
            className: 'custom-modal'
        }
        const {
            total_cap, formValid, posting
        } = this.state;

        console.log(formValid)


        return (
            <Modal {...ModalProps}>
                <i className="close-btn icon-cancel" onClick={toggle} />
                <ModalHeader>Update Total Caps</ModalHeader>
                <Form onSubmit={this.onSubmit} >
                    <ModalBody>
                        {/* <Row>
                            <Col className={'mb-4 text-center'}><h5>{UserProps.name}</h5></Col>
                        </Row> */}
                        <Row>
                            <Col xs={12} md={12}>
                                <FormGroup>
                                    <Label>Total Cap</Label>
                                    <Input
                                        type="text"
                                        name="total_cap"
                                        value={total_cap}
                                        onChange={this.handleChange}
                                        autoComplete="off"
                                        invalid={Utils.isInvalid("number", total_cap)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" size='sm' type='submit' disabled={!formValid || posting}>Save</Button>
                    </ModalFooter>
                </Form>
            </Modal>
        )

    }

}

export default TotalCapsModal;