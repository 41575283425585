import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, Row, Col, FormGroup, Input, Label } from 'reactstrap';
import DatePicker from 'react-date-picker';
import { Utils, Http, API } from 'Helpers';
import moment from 'moment';

class RemoveOldData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posting: false,
      formValid: false,
      from_date: moment().add(-15, 'days').toDate(),
      //moment().toDate()
      to_date: moment().add(-15, 'days').toDate(),
      password: ''
    }
  }

  onFromDateChange = (date) => {
    this.setState({ from_date: date }, () => {
      
    })
  }


  onToDateChange = (date) => {
    this.setState({ to_date: date }, () => {
    })
  }

  handleChange = (e, type) => {
    const name = !type ? e.target.name : type;
    let value = !type ? e.target.value : e;
    if (name === 'password') {
      value = value.trim()
    }
    this.setState({ [name]: value }, () => {
      this.validateForm()
    });
  };

  validateForm = () =>{
    const {password} = this.state;
    this.setState({
      formValid:
        password !==''})
  }

  

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({ posting: true })
    
    const {
      from_date,
      to_date,
      password
    } = this.state;

    let param = {
      "from_date": from_date,
      "to_date" : to_date,
      "password":password
     
    }
    
     Http.post(API.REMOVE_OLD_TRANSACTIONS, param).then(response => {
      this.setState({ posting: false },this.props.toggle)
      Utils.notify(response.message);
    }).catch(error => {
      this.setState({ posting: false })
    }); 
  }


  render() {
    const { isOpen, toggle } = this.props
    const { from_date, to_date, password, formValid, posting } = this.state
    const ModalProps = {
      isOpen,
      toggle,
      size: 'md',
      className: 'custom-modal'
    }

    return (
      <Modal {...ModalProps}>
        <i className="close-btn icon-cancel" onClick={toggle} />
        <ModalHeader>Remove Old Data</ModalHeader>
        <Form onSubmit={this.onSubmit} >
        <ModalBody>
          
            <Row>
              <Col>
                <FormGroup>
                  <Label>From Date</Label>
                  <DatePicker
                    onChange={this.onFromDateChange}
                    value={from_date}
                    maxDate={ moment().add(-15, 'days').toDate()}
                    calendarIcon={<i className='icon-calendar' />}
                    className='form-control'
                    clearIcon={null}
                    format="d/M/yyyy"
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>To date</Label>
                  <DatePicker
                    onChange={this.onToDateChange}
                    value={to_date}
                    maxDate={ moment().add(-15, 'days').toDate()}
                    calendarIcon={<i className='icon-calendar' />}
                    className='form-control'
                    clearIcon={null}
                    format="d/M/yyyy"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <FormGroup>
                  <Label>Master Password</Label>
                  <Input
                    type="password"
                    name="password"
                    value={password}
                    onChange={this.handleChange}
                    autoComplete="off"
                    invalid={Utils.isInvalid("password", password)}
                  />
                </FormGroup>
              </Col>
            </Row>
          
          <p className="help-form-txt mt20 grey-light">Note: Here bet history, transfer statement, profit & loss, All Matched data will be deleted from date to date.</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" size='sm' type='submit' disabled={!formValid || posting}>Submit</Button>
        </ModalFooter>
        </Form>
      </Modal>
    );
  }
}

RemoveOldData.defaultProps = {
  isOpen: true,
  toggle: () => { }
}
export default RemoveOldData;

