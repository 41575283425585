/* eslint-disable eqeqeq */
// wrapper for localstorage and auth object setting
import React, { Component, Fragment } from 'react';
import Validation from '../Validation'
import { CONSTANT, Images } from 'Constants'
import exif from 'exif-js';
import _ from "lodash";
import moment from 'moment'

import Moment from 'react-moment';
import 'moment-timezone';
import { notify } from 'react-notify-toast';
import { Http, API } from 'Helpers';
import { Auth } from 'Service';
import config from 'config';
import { components } from 'react-select';
import { Trans } from 'react-i18next';
import { Modal, Button } from 'reactstrap';
import NavigationPrompt from 'react-router-navigation-prompt';

let flex = 'FLEX'
let LINEUP_MASTER_DATA = []
let HEADER_CONFIG = []

let _this = null;
class NavigationPromptWrap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      when: false 
    }
  }
  
  static Init(when) {
    _this.setState({
      when
    })
  }
  render() {
    _this = this;
    const { when } = this.state;
    return (
      <NavigationPrompt
        renderIfNotActive={true}
        when={when}>
        {({ isActive, onCancel, onConfirm }) => {
          if (isActive) {
            console.log(isActive)
            return (
              <Modal isOpen={true} className='alert-dialog' size={'sm'}>
                <div className='title'><Trans>Alert!</Trans></div>
                <div><Trans>This will reset your team, are you sure you want to proceed?</Trans></div>
                <div className="alert-dialog-footer">
                  <Button color="success" onClick={onCancel}><Trans>Cancel</Trans></Button>
                  <Button color="secondary" onClick={onConfirm}><Trans>Continue</Trans></Button>
                </div>
              </Modal>
            );
          }
          return <div />
        }}
      </NavigationPrompt>

    )
  }
}
class SpinnerLoader extends Component {
  render() {
    return (
      <div className="page-loading">
        <svg className="spinner  " viewBox="0 0 50 50">
          <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="3"></circle>
          </svg>
          <div className="page-loading-title">Loading...</div>
      </div>
    )
  }
}


class Spinner extends Component {
  render() {
    return (
      <svg className={`spinner ${this.props.light ? 'light' : ''} ${this.props.className ? this.props.className : ''}`} viewBox="0 0 50 50">
        <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="3"></circle>
      </svg>
    )
  }
}

class FlexboxLoader extends Component {
  render() {
    const { style, init, className } = this.props;
    return (
      <div className={`flexbox-loader-wrapper ${className ? className: ''} ${init ? 'show' : ''}`} style={style}>
        <div className="flexbox-loader">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    )
  }
}

class GlobalLoader extends Component {
  render() {
    return (
      <div className="global-loader">
        <div className='page-loading'>
          <Spinner />
          <div className='page-loading-title'><Trans>Loading...</Trans></div>
        </div>
      </div>
    );
  }
}

class TriangleLoader extends Component {
  render() {
    return (
      <Fragment>
        <div className="loader-field">
          <svg viewBox="0 0 80 80">
            <circle id="test" cx="40" cy="40" r="32"></circle>
          </svg>
        </div>
        <div className="loader-field triangle">
          <svg viewBox="0 0 86 80">
            <polygon points="43 8 79 72 7 72"></polygon>
          </svg>
        </div>
        <div className="loader-field">
          <svg viewBox="0 0 80 80">
            <rect x="8" y="8" width="64" height="64"></rect>
          </svg>
        </div>
      </Fragment>
    )
  }
}

const statusIdRegex = /\/status(es)?\/(\d+)/;
var url = require('url');


const Utils = {
  getSelectedOdds: (odds, type) => {
    return type == 1 ? odds.ex.availableToBack : odds.ex.availableToLay
  },
  containsObject: (obj, list) => {
    var x;
    for (x in list) {
      if (list[x].selection_id == obj.selection_id) {
        return true;
      }
    }
    return false;
  },
  NavigationPrompt: (when) => {
    NavigationPromptWrap.Init(when)
  },

  TwoDecimalVal: (value) => {
    var num = Number(value)
    return num.toFixed(2)
  },

  ordinal_suffix_of: (i) => {
    if (typeof foo !== "number") {
      i = Number(i)
    }
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  },
  getDateYearsOld: () => {
    let date = new Date()
    let year = date.getFullYear() - 21
    date.setFullYear(year)
    return date;
  },

  isValid: (name, state) => {
    if(state == '' || state == null) return false;
    return !Validation.init(name, state)
  },

  isInvalid: (name, state) => {
    return Validation.init(name, state)
  },

  isFormValid: (forms, state) => {
    let arr = []
    _.map(forms, (item) => {
      let itemValidate = Validation.init(item, state[item]) === false;
      if (itemValidate) {
        arr.push(itemValidate);
      }
    })
    return arr.length === forms.length;
  },

  setExistingUser: (data) => {
    localStorage.setItem('existing_user', JSON.stringify(data));
  },
  getExistingUser: () => {
    const existing_user = localStorage.getItem('existing_user');
    if (!existing_user) {
      return null;
    }
    return JSON.parse(existing_user);
  },

  setProfile: (data) => {
    localStorage.setItem('profile', JSON.stringify(data));
  },


  getProfile: () => {
    const profile = localStorage.getItem('profile');
    if (!profile) {
      return null;
    }
    return JSON.parse(profile);
  },
  removeProfile: () => {
    localStorage.removeItem('profile');
  },

  setLineup: (lineup) => {
    localStorage.setItem(`lineup`, JSON.stringify(lineup));
  },

  getLineup: () => {
    const lineup = localStorage.getItem(`lineup`);
    if (!lineup) {
      return null;
    }
    return JSON.parse(lineup);
  },

  removeLineup: () => {
    localStorage.removeItem(`lineup`);
  },
  
  setContestCookie: (cname, cvalue, exdays) => {
    var _date = new Date(exdays); // some mock date
        _date.setTime(_date.getTime());
    var expires = "expires=" + _date.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  },
  
  setContest: (contest) => {
    localStorage.setItem(`contest`, JSON.stringify(contest));
    // Utils.setContestCookie(`contest`, JSON.stringify(contest), contest.collection_scheduled_date);
  },

  getContest: () => {
    const contest = localStorage.getItem(`contest`);
    if (!contest) {
      return null;
    }
    return JSON.parse(contest);
  },

  setMiniLeagueContestId: (contest) => {
    localStorage.setItem(`mini_league_contest_id`, JSON.stringify(contest));
  },

  getMiniLeagueContestId: () => {
    const contest = localStorage.getItem(`mini_league_contest_id`);
    if (!contest) {
      return null;
    }
    return JSON.parse(contest);
  },
  removeGetMiniLeagueContestId: () => {
    localStorage.removeItem('mini_league_contest_id');
  },

  DefaultPlayer: (item, index) => {
    let PlayerObj = {
      position: item.position,
      is_bench_player: 0,
      bench_order: 0,
      player_role: 0,
      player_uid: null,
      salary: 0,
    }
    return PlayerObj
  },

  IsPlayerExist: (obj, list) => {
    var x;
    for (x in list) {
      if (list[x].player_uid == obj.player_uid) {
        return true;
      }
    }
    return false;
  },
  IsPlayerField: (obj, list) => {
    var x;
    for (x in list) {
      if (list[x].player_uid == obj.player_uid && list[x].is_bench_player == obj.is_bench_player && obj.is_bench_player == 0) {
        return true;
      }
    }
    return false;
  },

  AvailableSalary: (SalaryCap, LineupList) => {
    let salary = _.sumBy(LineupList, function (o) {
      return parseFloat(Number(o.salary))
    })
    return (SalaryCap - salary) || 0;
  },

  playerNameSort: (name) => {
    if (!name) return
    var str = name.split(' ');
    str[0] = str[0].charAt(0)
    return str[0] + '. ' + str[str.length - 1]
  },

  SelectPlayerCount: (data) => {
    if (_.isEmpty(data)) return 0;
    let result = _.filter(data, (o) => { return o.player_uid != undefined })
    return result.length;
  },

  SelectedPositionCount: (data, val) => {
    let result = _.filter(data, (o) => { return val.position != '' ? (o.position == val.position && o.player_uid) : o.player_uid != null })
    return result.length;
  },

  AddPlayerToLineup: (option) => {
    let { player, lineup, salary, max_player_per_team, all_position } = option;

    const isMaxPlayerPerTeam = () => {
      let filterArr = _.filter(lineup, (o) => {
        return o.team_league_id == player.team_league_id
      })
      return !(filterArr.length < Number(max_player_per_team));
    }

    const isFlexBlank = () => {
      let pos = _.filter(lineup, (item) => {
        return item.position == flex && item.player_uid == null
      })
      return pos.length == 1;
    }

    const ExecuteLineup = () => {
      let i = 0;
      lineup = _.sortBy(lineup, 'is_bench_player')
      while (i < lineup.length) {
        if (lineup[i].position === player.position && !lineup[i].player_uid) {
          player.is_bench_player =lineup[i].is_bench_player
          player.bench_order = lineup[i].bench_order
          player.player_role = lineup[i].player_role
          lineup[i] = player;

          if (Utils.SelectPlayerCount(lineup) == (lineup.length - 1)) {
            _.map(lineup, (item) => {
              if (item.position == flex) {
                item.isFlexHide = false;
              }
              return item;
            })
          }
          break;
        }
        i++;
      }
      return lineup;
    }

    let Position = _.filter(all_position, (o) => {
      return o.position == player.position
    })

    const ExecuteFlexLineup = () => {
      let FlexPosition = _.filter(all_position, (o) => {
        return o.position == flex
      })[0]
      let FlexLineup = _.map(lineup, (item) => {
        if (item.position == flex && FlexPosition.allowed_position.includes(player.position) ) {
          let playerClone = _.cloneDeep(player)
          playerClone.roster_position = playerClone.position;
          playerClone.position = flex;
          item = playerClone;
        }
        return item;
      })
      return FlexLineup;
    }

    return new Promise((resolve, reject) => {
      switch (true) {
        
        case Utils.SelectPlayerCount(lineup) === lineup.length:
          reject(CONSTANT.MSZ_LINEUP_FULL)
          break;

        case salary < parseInt(player.salary, 10):
          reject(CONSTANT.MSZ_BUDGET_INSUFFICIENT)
          break;

        case Position.length == 0:
          reject(player.position+' '+CONSTANT.MSZ_POSITION_NOT_SELECTED)
        break;

        case isFlexBlank() && (!isMaxPlayerPerTeam()):
          resolve(ExecuteFlexLineup())
          break;

        case Utils.SelectedPositionCount(lineup, player) === Number(Position[0].max_player_per_position):
          reject(CONSTANT.MSZ_MAX_POSITION_SELECTED)
          break;

        case isMaxPlayerPerTeam():
          reject(CONSTANT.MSZ_MAX_PLAYER_PER_TEAM)
          break;

        default:
          resolve(ExecuteLineup())
          break;
      }
    })
  },

  RemovePlayerFromLineup: (option) => {
    let { player, lineup } = option;

    const ExecuteLineup = () => {
      let i = 0;
      while (i < lineup.length) {
        if (lineup[i].player_uid === player.player_uid) {
          let DefaultPlayer = Utils.DefaultPlayer(player);
          let isFlexHide = Utils.SelectPlayerCount(lineup) <= (lineup.length - 1)

          DefaultPlayer.is_bench_player = lineup[i].is_bench_player
          DefaultPlayer.bench_order = lineup[i].bench_order
          DefaultPlayer.player_role = lineup[i].player_role

          if (lineup[i].position == flex) {
            DefaultPlayer = { ...DefaultPlayer, isFlexHide: isFlexHide }
          }
          lineup[i] = DefaultPlayer;

          if (Utils.SelectPlayerCount(lineup) < (lineup.length - 1)) {
            _.map(lineup, (item) => {
              if (item.position == flex && item.player_uid == null) {
                item.isFlexHide = true;
              }
              return item;
            })
          }
          break;
        }
        i++;
      }
      return lineup;
    }

    return new Promise((resolve, reject) => {
      resolve(ExecuteLineup());
    })
  },

  RandomBenchPlayer: (option) => {
    const { lineup, formation } = option;
    
    for (let i = 0; i < formation.length; i++) {

      const element = formation[i];
      var filterArr = lineup.filter(function (item) {
        return item.position == element.position
      })
      _.map(filterArr, (item, idx) => {
        item.is_bench_player = "0"
        if (idx < element.number_of_bench_player) {
          item.is_bench_player = "1"
        }
        return item;
      })
    }
    // return lineup
    return new Promise((resolve, reject) => {
      resolve(lineup)
    })
  },

  RandomFlex: (option) => {
    const { lineup } = option;


    let FlexTobeFltr = [];
    let FlexPlayer = {};
    let FLEX = _.filter(lineup, (o) => {
      let tmp = "QB,RB,WR,TE";
      if (tmp.includes(o.position) && o.is_bench_player == 0) {
        FlexTobeFltr.push(o);
      }
      return (o.position === flex) || (o.roster_position === flex)
    })

    function getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    if (_.isEmpty(FLEX)) {
      let idx = getRandomInt(0, FlexTobeFltr.length - 1);
      FlexTobeFltr[idx].roster_position = flex;
      FlexPlayer = FlexTobeFltr[idx];

      _.map(lineup, (item, idx) => {
        if (item.player_uid === FlexPlayer.player_uid) {
          item = FlexPlayer;
        } else {
          item.roster_position = null;
        }
        return item;
      })
    }
    return lineup;
  },

  LineupOptimize: (lineup) => {
    let LineupList = []
    _.map(lineup, (item) => {
      LineupList.push({
        "player_team_id": item.player_team_id,
        "position": item.roster_position === flex ? item.roster_position : item.position,
        "is_bench_player": item.is_bench_player
      })

      return item;
    })
    return LineupList;
  },


  getStatusId: (string) => {
    let uriSet = string.match(/\bhttps?:\/\/\S+/gi);
    let uri;

    _.map(uriSet, (item) => {
      let parsed = url.parse(item);
      if (parsed.host !== 'twitter.com') {
        return null;
      }
      uri = item;
    })

    var parsed = url.parse(uri);
    if (parsed.path.indexOf('status') === -1) {
      return null;
    }

    var match = statusIdRegex.exec(parsed.path);
    if (match === null) {
      return null;
    }

    return {
      id: match[2],
      host: parsed.host
    };

  },

  getUrlParams: (search) => {
    if (search == "") return {};
    let hashes = search.slice(search.indexOf('?') + 1).split('&')
    return hashes.reduce((params, hash) => {
      let [key, val] = hash.split('=')
      return Object.assign(params, { [key]: JSON.parse(decodeURIComponent(val)) })
    }, {})
  },

  omit: () => (obj, arr) => Object.keys(obj).reduce((res, key) => {
    if (arr.indexOf(key) === -1) {
      res[key] = obj[key]
    }
    return res
  }, {}),

  getUtcToLocal: (date) => {
    let localDate = moment(date).utc(true).local().format();
    return localDate;
  },
  getTodayTimestamp: () => {
    let date = new Date()
    let today = moment(date).utc(true).local().valueOf();
    return today;
  },

  setCookie: (cname, cvalue, exdays) => {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  },

  getCookie: (cname) => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  },

  numberWithCommas: (x, symbol = '') => {
    if(x == undefined) return x;
    x = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return symbol+x || symbol+'0';
  },
  onImgError: () => {
    return Images.DEFAULT_IMG
  },
  getMasterData: () => {
    return LINEUP_MASTER_DATA || [];
  },
  setMasterData:  (data) => {
    LINEUP_MASTER_DATA = data;
  },
  getHeaderConfig: () => {
    return HEADER_CONFIG || [];
  },
  setHeaderConfig:  (data) => {
    HEADER_CONFIG = data;
  },
  dateTimeFormat: (date, format = CONSTANT.DATE_FORMAT) => {
    let localDate = moment.utc(date).local().format();
    return(
      <Moment format={format} tz={CONSTANT.TIMEZONE}>{localDate}</Moment>
    )
  },
  DateFormat: (date, format = CONSTANT.DATE_FORMAT) => {
    return(
      <Moment format={format} tz={CONSTANT.TIMEZONE}>{date}</Moment>
    )
  },
  DobFormat: (date) => {
    return moment(date).format('MMM DD, YYYY')
  },
  DobFormatView: (date) => {
    return moment(date).format('MMM DD, YYYY')
  },

  Calculate: (type = '', item = {}) => {
    let total_wager = Number(item.total_wager_amt);
    let total_max_win = _.sumBy(item.bets, function(o) { return (Number(o.wager_amt) * Number(o.payout_ratio)); });

    switch (type) {
      case '1': // Winnings
        let Winnings = _.sumBy(item.bets, function(o) { 
            return (o.bet_point <= item.total_score) ? (Number(o.wager_amt) * Number(o.payout_ratio)) : 0;
        });
        return Utils.numberWithCommas(Winnings);
        
      case '2': // Max Pay
        return Utils.numberWithCommas(total_max_win + total_wager)
      
      case '3': // Tier Count
      let _arr = []
        _.forEach(item.bets, o => {
          if(o.bet_point <= item.total_score) {
            _arr.push(o)
          }
        })
        return _arr.length;

      case '4':
        return Utils.numberWithCommas(item.wager_amt * item.payout_ratio)

      default:
        break;
    }
  },

  notify: (message = "", type = 'success', duration=2000, customColor= { background: '#0E1717', text: "#FFFFFF" }) => {
    notify.show(message, type, duration, customColor);
  },
  isDev: () => {
    return process.env.NODE_ENV === 'development'
  },
  mediaQuery: (innerWidth, name) => {
    let max = CONSTANT[name]
    return max > innerWidth
  },
  resizeBind: (fun) => {
    window.addEventListener("resize", fun)
  },
  resizeUnbind: (fun) => {
    window.removeEventListener("resize", fun);
  },


  setLeagues: (leagues) => {
    localStorage.setItem('leagues', JSON.stringify(leagues));
  },
  getLeagues: () => {
    const leagues = localStorage.getItem('leagues');
    if (!leagues) {
      return null;
    }
    return JSON.parse(leagues);
  },

  removeLeagues: () => {
    localStorage.removeItem('leagues');
  },

  

  setSelectedLeague: (selected_league) => {
    localStorage.setItem('selected_league', JSON.stringify(selected_league));
  },
  getSelectedLeague: () => {
    const selected_league = localStorage.getItem('selected_league');
    if (!selected_league) {
      return null;
    }
    return JSON.parse(selected_league);
  },

  removeSelectedLeague: () => {
    localStorage.removeItem('selected_league');
  },
  GetSportLeagues: () => {
    return new Promise((resolve, reject) => {
      let param = {
          "sports_id": CONSTANT.SPORTS_ID
      }
      Http.post(API.GET_SPORT_LEAGUES, param).then(response => {
          Utils.setLeagues(response.data.result)
          resolve(response.data.result)
      }).catch(error => {
          this.setState({posting: false})
          resolve(null)
      }); 

    })

  },
  IsPlayerRoleExist: (role, list) => {
    var x;
    for (x in list) {
      if (list[x].player_role == role) {
        return true;
      }
    }
    return false;
  },

  
  geti18nextLng: () => {
    // const i18nextLng = localStorage.getItem('i18nextLng');
    const i18nextLng = Utils.getCookie('cf_lang');
    if (!i18nextLng) {
      return CONSTANT.DEFAULT_LNG;
    }
    return i18nextLng;
  },
  s3UrlConvert: (param) => {
    let s3 = config.s3;
    let url = [
      s3.BUCKET,
      s3.BUCKET_DATA_PREFIX,
      Utils.geti18nextLng(),
      ...param
    ]
    url = url.join('_')
    url = url.replace("/_", "/")
    return url + '.json'
  },

  SetSessionStorage: (key, value) => {
    sessionStorage.setItem(key, JSON.stringify(value));
  },

  GetSessionStorage: (key) => {
    const data = sessionStorage.getItem(key);
    if (!data) {
      return null;
    }
    return JSON.parse(data);
  },
  RemoveSessionStorage: (key) => {
    sessionStorage.removeItem(key);
  },

  DropdownIndicator: (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <i className="icon-down"/>
      </components.DropdownIndicator>
    )
  },
  readFile: (file) => {
    return new Promise(resolve => {
      var reader = new FileReader();
      reader.onload = e => resolve(e.target.result);
      reader.readAsDataURL(file);
    });
  },
  createImage: (data) => {
    return new Promise(resolve => {
      const img = document.createElement('img');
      img.onload = () => resolve(img);
      img.src = data;
    })
  },

  rotate: (type, img) => {
    return new Promise(resolve => {
      const canvas = document.createElement('canvas');
  
      exif.getData(img, function () {
        var orientation = exif.getAllTags(this).Orientation;
  
        if ([5, 6, 7, 8].indexOf(orientation) > -1) {
          canvas.width = img.height;
          canvas.height = img.width;
        } else {
          canvas.width = img.width;
          canvas.height = img.height;
        }
  
        var ctx = canvas.getContext("2d");
  
        switch (orientation) {
          case 2:
            ctx.transform(-1, 0, 0, 1, img.width, 0);
            break;
          case 3:
            ctx.transform(-1, 0, 0, -1, img.width, img.height);
            break;
          case 4:
            ctx.transform(1, 0, 0, -1, 0, img.height);
            break;
          case 5:
            ctx.transform(0, 1, 1, 0, 0, 0);
            break;
          case 6:
            ctx.transform(0, 1, -1, 0, img.height, 0);
            break;
          case 7:
            ctx.transform(0, -1, -1, 0, img.height, img.width);
            break;
          case 8:
            ctx.transform(0, -1, 1, 0, 0, img.width);
            break;
          default:
            ctx.transform(1, 0, 0, 1, 0, 0);
        }
  
        ctx.drawImage(img, 0, 0, img.width, img.height);
        ctx.toBlob(resolve, type);
      });
    })
  }
  ,
 parseErrorMessage(response) {
    var errorMsg = (response.message && response.message != "" )? response.message : response.global_error;
    if (errorMsg == "") {
      for (var key in response.error) {
        errorMsg = response.error[key];
        if (errorMsg != "") {
          break;
        }
      }
    }
    return errorMsg
  },
  HandleResponse: (response) => {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if ([401, 403].indexOf(response.status) !== -1) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                Auth.logout();
                // location.reload(true);
            }

           // const error = (data && data.message) || response.statusText;
           var errorMsg = (data.message && data.message != "" )? data.message : data.global_error;
           if (errorMsg == "") {
             for (var key in data.error) {
               errorMsg = data.error[key];
               if (errorMsg != "") {
                 break;
               }
             }
           }
          return Promise.reject(errorMsg);
        }
        return data;
    });
  }

}

export default { ...Utils, Spinner,SpinnerLoader, FlexboxLoader, TriangleLoader, NavigationPromptWrap, GlobalLoader };