import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap';
import { _ } from 'Helpers';
class ConfirmationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posting: false,
      formValid: false,
     }
  }

  render() {
    const { isOpen, toggle,UserProps,message ,title,callback } = this.props
    const ModalProps = {
      isOpen,
      toggle,
      size: 'md',
      className: 'custom-modal confirm'
    }
 
    return (
      <Modal {...ModalProps}>
        <i className="close-btn icon-cancel" onClick={toggle} />
        <ModalHeader>{(title+" "+((_.isUndefined(UserProps) || _.isUndefined(UserProps.username))?'':UserProps.username))}</ModalHeader>
          <ModalBody>
           <span className="desc">{message+' '+((_.isUndefined(UserProps) || _.isUndefined(UserProps.username))?'':UserProps.username+" ?")}</span>
           </ModalBody>
          <ModalFooter>
            <Button color="primary" size='sm' onClick={()=>toggle()} >No</Button>
            <Button color="primary" size='sm' onClick={()=>{
              callback()   
            }}  >Yes</Button>
          </ModalFooter>
      </Modal>
    );
  }
}

ConfirmationModal.defaultProps = {
  isOpen: true,
  toggle: () => { }
}
export default ConfirmationModal;
