import React, { Component, Fragment } from 'react';
import { _ } from 'Helpers';


class Scoreboard extends Component {
  render() {
    const {
      ScoresData,
      //EventDetails,
      sports_id } = this.props
    const BlankBanner = () => ''
    // const BlankBanner = () => <div className="odds-banner" />
    // function NameAbbr(str) {
    //   var matches = str.match(/\b(\w)/g);
    //   return matches.join('')
    // }
    // const CricketCell = ({ data, type }) => {
    //   data = JSON.parse(data)[type]
    //   return (
    //     <div className="cell">
    //       <div className="event-name-abbr">
    //         {
    //           type === 'home' ?
    //             <Fragment>
    //               {data.name}
    //               {!_.isUndefined(data.inning1) ? ` - ${data.inning1.runs}/${data.inning1.wickets}` : ' - 0/0'}
    //             </Fragment>
    //             :
    //             <Fragment>
    //               {!_.isUndefined(data.inning1) ? `${data.inning1.runs}/${data.inning1.wickets} - ` : ' 0/0 - '}
    //               {data.name}
    //             </Fragment>

    //         }
    //       </div>
    //       <div className="event-over">
    //         {!_.isUndefined(data.inning1) ? `(${data.inning1.overs} Ov)` : '(0.0 Ov)'}
    //       </div>
    //     </div>
    //   )
    // }
    const CricketCell = ({ data, type }) => {
      // if(_.isUndefined(data[type])){
      //   return <div></div>
      // }
      // data = JSON.parse(data)[type];
      data = JSON.parse(data);
      let wicket = !_.isUndefined(data['i'+type]) ? data['i'+type].wk !== 'ALL_OUT' ? data['i'+type].wk : '10' : '';
      
      return (
        <div className="cell">
          <div className="event-name-abbr">
            {
              // type === 'home' ?
                <Fragment>
                  {`${data['t'+type].n}`}
                  {/* {`${NameAbbr(data.name)}`} */}
                  {!_.isUndefined(data['i'+type]) ? ` - ${data['i'+type].sc}/${wicket}` : ' - 0/0'}
                </Fragment>
                // :
                // <Fragment>
                //   {!_.isUndefined(data.i2) ? `${data.i2.sc}/${wicketA} - ` : ' 0/0 - '}
                //   {`${data.t2.n}`}
                //   {/* {`${NameAbbr(data.name)}`} */}
                // </Fragment>

            }
          </div>
         {
          // type === 'home' ?
          <div className="event-over">
            {!_.isUndefined(data['i'+type]) ? `(${data['i'+type].ov} Ov)` : '(0.0 Ov)'}
          </div>
        //   :
        //   <div className="event-over">
        //   {!_.isUndefined(data['i'+type]) ? `(${data['i'+type].ov} Ov)` : '(0.0 Ov)'}
        // </div>
          }
        </div>
      )
    }


    const SoccerCell = ({ data, type }) => {
      data = JSON.parse(data)[type]
      return (
        <div className="cell">
          <div className="event-name-abbr">
            {
              type === 'home' ?
                <Fragment>
                  {data.name}{" - "}{data.score}
                </Fragment>
                :
                <Fragment>
                  {data.score}{" - "}{data.name}
                </Fragment>
            }
          </div>
          {/* <div className="event-over">
            {!_.isUndefined(data.inning1) ? `(${data.inning1.overs} Ov)` : '(0.0 Ov)'}
          </div> */}
        </div>
      )
    }

    const TennisCell = ({ data, type }) => {
      data = JSON.parse(data)[type]
      return (
        <Fragment>
          <div className="cell">
            <div className="event-name-abbr-sm">
              {data.name || 'Player Name'}
            </div>
          </div>
          <div className="cell gameSequence">
            {_.map(data.gameSequence, (item, key) => <span {...{ key }}>{item}</span>)}
          </div>
        </Fragment>
      )
    }
    const Template = () => {
      if(_.isEmpty(ScoresData)) return ''
      switch (sports_id) {
        case "2":
          return (
            <div className="score-list">
              <CricketCell data={ScoresData.score} type='1' />
              <div className="cell vs">vs</div>
              <CricketCell data={ScoresData.score} type='2' />
            </div>
          )
        case "1":
          return (
            <div className="score-list">
              <SoccerCell data={ScoresData.score} type='home' />
              <div className="cell vs">vs</div>
              <SoccerCell data={ScoresData.score} type='away' />
            </div>
          )
        case "3":
          return (
            <Fragment>
              <div className="score-list">
                <TennisCell data={ScoresData.score} type='home' />
              </div>
              <div className="score-list">
                <TennisCell data={ScoresData.score} type='away' />
              </div>
            </Fragment>
          )
        default:
          return '';
      }
    }

    return (
      !_.isEmpty(ScoresData) ?
        <div className="odds-banner scoreboard">
          <div className="inner-scoreboard">
            {/* <div className='league-name'>{EventDetails.league_name}</div>
            <div className='event-name'>{EventDetails.event_name}</div> */}
            <Template />
          </div>
        </div>
        :
        <BlankBanner />
    )
  }
}
Scoreboard.defaultProps = {
  ScoresData: [],
  EventDetails: {},
  sports_id: ''
}
export default Scoreboard;