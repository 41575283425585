const EVENT = {
  "MARKET"                : "marketUpdated",
  "MARKET_SP"             : "marketUpdatedSP",
  "FANCY"                 : "fancySessionUpdated",
  "FANCY_REPLACED"        : "fancySessionReplaced",
  "SCORE"                 : "scoreUpdated",
  "EVENT"                 : "eventUpdated",
  "PLACEDBET"             : "placedBet",
  "STATUSUPDATEDMK"       : "StatusUpdatedMk",
  "PROVIDER_UPDATED"      : "providerUpdated",
  "BOOKMAKER_MARKET"       : "bookmakerUpdated"
}

export default { ...EVENT }