import React, { Component } from 'react'

class MaintenanceView extends Component {
  render() {
    return (
        <div className="maintenanceview"> </div>
    )
  }
}

export default MaintenanceView;
