/* eslint-disable eqeqeq */
import { Auth, Utils } from "Helpers";
import { notify } from 'react-notify-toast';
import config from "config";
import { CONSTANT } from "Constants";
// import i18next from 'i18next';

function status(res) {
  if (res.status >= 200 && res.status < 400) {
    return Promise.resolve(res);
  } else if (res.status === 401) {
    // unauthorized
    localStorage.removeItem('currentUser')
    window.location.replace('/login')
  }
  return Promise.reject(res);
}

function json(res) {
  if (
    res &&
    res.headers &&
    res.headers.get("content-type").startsWith("application/json")
  ) 
    return res.json().then(response=>{
      if (response.response_code >= 200 && response.response_code < 400) {
        return response;
      } else {
        // Notify

        notify.show(response.message, "error", 2500);
        throw response;
      }
    });

  return {};
}

function parseErrorMessage(response) {
  var errorMsg = (response.message && response.message != "" )? response.message : response.global_error;
  if (errorMsg == "") {
    for (var key in response.error) {
      errorMsg = response.error[key];
      if (errorMsg != "") {
        break;
      }
    }
  }
  return errorMsg
}

function error(res, errorNotifyHide) {
  if (
    res &&
    res.headers &&
    res.headers.get("content-type").startsWith("application/json")
  ) {
    return res.json().then(err => {
      // if(err.data.deadline_time) {
      //   err.message = err.message.replace(/%s/g, moment(err.data.deadline_time).format('MMM DD, YYYY'));
      // }
      // Notify
      if(!errorNotifyHide) {
        notify.show(parseErrorMessage(err), "error", 2500);
      }
      throw err;
    });
  }

  if (res) {
    throw res;
  }

  return {};
}

export function get(url) {
  const auth = Auth.getAuth();
  const token = (!!auth && auth) || null;
  const settings =
    token !== null
      ? {
          headers: {
            session_key: token,
            'Accept-Language': Utils.geti18nextLng('cf_lang')
          }
        }
      : {
          headers: {
            'Accept-Language': Utils.geti18nextLng('cf_lang')
          }
      };

  return fetch(url, settings)
    .then(status)
    .then(json)
    .catch(error);
}

export function post(url, body = {}, errorNotifyHide = false) {
  const auth = Auth.getAuth();
  const token = (!!auth && auth) || null;
  const settings =
    token !== null
      ? {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
            'Accept-Language': Utils.geti18nextLng('cf_lang'),
            session_key: token
          }
        }
      : {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
            'Accept-Language': Utils.geti18nextLng('cf_lang')
          }
        };

  return fetch(url, settings)
    .then(status)
    .then(json)
    .catch((res) => error(res, errorNotifyHide));
}

export function s3(s3url, url, body = {}, errorNotifyHide = false) {
  const auth = Auth.getAuth();
  const token = (!!auth && auth) || null;
  const settings =
    token !== null
      ? {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
            'Accept-Language': Utils.geti18nextLng('cf_lang'),
            session_key: token
          }
        }
      : {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
            'Accept-Language': Utils.geti18nextLng('cf_lang')
          }
        };

  const s3Setting = {
    mode: 'cors',
    method: 'GET',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'text/plain',
      'Access-Control-Allow-Methods': 'PUT, POST, DELETE, GET',
      'Access-Control-Allow-Origin': '*'
    }
  }
  const RestAPI = function(){
    return fetch(url, settings)
      .then(status)
      .then(json)
      .catch((res) => error(res, errorNotifyHide));
  }

  if(config.s3.BUCKET_STATIC_DATA_ALLOWED == 1) {
    return fetch(s3url, s3Setting)
      .then((response) => {
        return response.json()
      })
      .then(response => {
        return { data: response, response_code: CONSTANT.SUCCESS_CODE };
      })
      .catch((error) => {
        return RestAPI()
      });
  } else {
    return RestAPI()
  }
}




export default { get, post, s3 };
//Loginsessionkey