// Config
import config from "../../config";
const SUCCESS_CODE = 200;

const API = {
    // AUTH
    LOGIN: `${config.apiGateway.URL}/auth/dologin`,
    LOGOUT: `${config.apiGateway.URL}/auth/logout`,

    // MASTER 
    
    //USER
    GET_USER_LIST: `${config.apiGateway.URL}/users/get_own_users`,
    ADD_USER: `${config.apiGateway.URL}/users/add_user`,
    CHANGE_PASSWORD: `${config.apiGateway.URL}/users/change_password`,
    BALANCE_IN_OUT: `${config.apiGateway.URL}/users/balance_in_out`,
    GET_SPORTS_SETTINGS: `${config.apiGateway.URL}/users/get_sports_settings`,
    SAVE_SPORTS_SETTINGS: `${config.apiGateway.URL}/users/save_sports_settings`,
    LOCK_UNLOCK_BET: `${config.apiGateway.URL}/users/lock_unlock_bet`,
    LOCK_UNLOCK_UM_BET: `${config.apiGateway.URL}/users/lock_unlock_um_bet`,
    LOCK_UNLOCK: `${config.apiGateway.URL}/users/active_inactive`,
    CLOSE_ACCOUNT: `${config.apiGateway.URL}/users/close_account`,
    UPDATE_USER_VIEW: `${config.apiGateway.URL}/users/update_user_view`,
    LOCK_UNLOCK_CASINO_BET: `${config.apiGateway.URL}/users/lock_unlock_casino_bet`,
    LOCK_UNLOCK_LIVECASINO_BET: `${config.apiGateway.URL}/users/lock_unlock_livecasino_bet`,
    UPDATE_REFERENE:`${config.apiGateway.URL}/users/update_reference`,
    LOCK_UNLOCK_BET_BY_ADMIN:`${config.apiGateway.URL}/users/lock_unlock_bet_by_admin`,
    LOCK_UNLOCK_SPORTSBOOK_BET:`${config.apiGateway.URL}/users/lock_unlock_sportsbook_bet`,

    //GLOBAL SETTING 
    ADD_NOTIFICATION: `${config.apiGateway.URL}/settings/add_notification`,
    GET_NOTIFICATIONS: `${config.apiGateway.URL}/settings/get_notifications`,
    UPDATE_NOTIFICATION_STATUS: `${config.apiGateway.URL}/settings/update_notification_status`,
    DELETE_NOTIFICATION: `${config.apiGateway.URL}/settings/delete_notification`,
    ONLINE_USERS: `${config.apiGateway.URL}/settings/online_users`,
    LOGOUT_USER: `${config.apiGateway.URL}/settings/logout_user`,
    LOGOUT_ALL_USERS: `${config.apiGateway.URL}/settings/logout_all_users`,
    GET_GLOBAL_SETTINGS: `${config.apiGateway.URL}/settings/get_global_settings`,
    SAVE_GLOBAL_SETTINGS: `${config.apiGateway.URL}/settings/save_global_settings`,
    PROFILE_DETAIL: `${config.apiGateway.URL}/profile/detail`,
    PROFILE_CHANGE_PASSWORD: `${config.apiGateway.URL}/profile/change_password`,
   
    //MARKET WATCH
    GET_ALL_SPORTS: `${config.apiGateway.URL}/sportsettings/get_all_sports`,
    GET_FIXTURES: `${config.apiGateway.URL}/marketwatch/get_fixtures`,
    BET_LIST: `${config.apiGateway.URL}/marketwatch/bet_list`,
    CANCEL_BET: `${config.apiGateway.URL}/marketwatch/cancel_bet`,
    GET_SB_FIXTURES: `${config.apiGateway.URL}/marketwatch/get_sb_fixtures`,
    UPDATE_MARKETLIST_MATCHES_STATUS: `${config.apiGateway.URL}/marketwatch/update_marketlist_matches_status`,
    EXPOSURE_DATA: `${config.apiGateway.URL}/marketwatch/exposure_data`,
    GET_ACTIVE_LEAGUES_LIVECARDS_MARKET_WATCH: `${config.apiGateway.URL}/marketwatch/get_active_leagues_livecards`,
    BET_LIST_LIVECARDS: `${config.apiGateway.URL}/marketwatch/bet_list_livecards`,
    GET_EVENT_MARKET: `${config.apiGateway.URL}/marketwatch/get_event_market`,
    MARKET_LOCK_BETTING_CASINO: `${config.apiGateway.URL}/marketwatch/market_lock_betting_casino`,
    




    //MANAGE SERIES
    GET_ACTIVE_LEAGUES: `${config.apiGateway.URL}/matches/get_active_leagues`,
    ACTIVE_INACTIVE_LEAGUE: `${config.apiGateway.URL}/matches/active_inactive_league`,
    ACTIVE_INACTIVE_FIXTURE: `${config.apiGateway.URL}/matches/active_inactive_fixture`,
    GET_FIXTURE_BY_LEAGUE: `${config.apiGateway.URL}/matches/get_fixtures_by_league`,
    GET_FIXTURE_BY_MARKET: `${config.apiGateway.URL}/matches/get_fixture_market`,
    ACTIVE_INACTIVE_FIXTURE_MARKET: `${config.apiGateway.URL}/matches/active_inactive_fixture_market`,
    GET_SPORTS_BY_BM: `${config.apiGateway.URL}/matches/get_sports_by_bm`,
    GET_EXCHANGE_MARKET: `${config.apiGateway.URL}/matches/get_exchange_market`,
    GET_FANCY_MARKET: `${config.apiGateway.URL}/matches/get_fancy_market`,
    UPDATE_EXCHANGE_MARKET_STATUS: `${config.apiGateway.URL}/matches/update_exchange_market_status`,
    UPDATE_FANCY_MARKET_STATUS: `${config.apiGateway.URL}/matches/update_fancy_market_status`,
    
    GET_ACTIVE_LEAGUES_SB: `${config.apiGateway.URL}/matches/get_active_leagues_sb`,
    GET_FIXTURES_BY_LEAGUE_SB: `${config.apiGateway.URL}/matches/get_fixtures_by_league_sb`,
    ACTIVE_INACTIVE_LEAGUE_SB: `${config.apiGateway.URL}/matches/active_inactive_league_sb`,
    ACTIVE_INACTIVE_FIXTURE_SB: `${config.apiGateway.URL}/matches/active_inactive_fixture_sb`,
    GET_SB_MARKET: `${config.apiGateway.URL}/matches/get_sb_market`,
    UPDATE_SB_MARKET_STATUS: `${config.apiGateway.URL}/matches/update_sb_market_status`,
    GET_ACTIVE_LEAGUES_LIVECARDS: `${config.apiGateway.URL}/matches/get_active_leagues_livecards`,
    ACTIVE_INACTIVE_LEAGUES_LIVECARDS: `${config.apiGateway.URL}/matches/active_inactive_leagues_livecards`,
    GET_LEAGUES_LIVECASINO: `${config.apiGateway.URL}/matches/get_leagues_livecasino`,
    GET_GAMES_LIVECASINO: `${config.apiGateway.URL}/matches/get_games_livecasino`,
    ACTIVE_INACTIVE_LEAGUES_LIVECASINO: `${config.apiGateway.URL}/matches/active_inactive_leagues_livecasino`,
    ACTIVE_INACTIVE_GAMES_LIVECASINO: `${config.apiGateway.URL}/matches/active_inactive_games_livecasino`,

    //DELETE SERIES
    GET_ACTIVE_LEAGUES_DS: `${config.apiGateway.URL}/deleteseries/get_active_leagues`,
    GET_ACTIVE_LEAGUES_SB_DS: `${config.apiGateway.URL}/deleteseries/get_active_leagues_sb`,
    GET_SPORTS_BY_BM_DS: `${config.apiGateway.URL}/deleteseries/get_sports_by_bm`,
    DELETE_LEAGUE: `${config.apiGateway.URL}/deleteseries/delete_league`,
    GET_INACTIVE_LEAGUES_DS: `${config.apiGateway.URL}/deleteseries/get_inactive_leagues`,
    GET_INACTIVE_LEAGUES_SB_DS: `${config.apiGateway.URL}/deleteseries/get_inactive_leagues_sb`,
    ROLLBACK_DELETE_LEAGUE: `${config.apiGateway.URL}/deleteseries/rollback_deleted_league`,

    //Staff
    GET_ADMIN_GROUP: `${config.apiGateway.URL}/subadmin/get_admin_group`,
    SAVE_ADMIN_GROUP: `${config.apiGateway.URL}/subadmin/save_group`,
    SAVE_ADMIN: `${config.apiGateway.URL}/subadmin/save_admin`,
    UPDATE_ADMIN: `${config.apiGateway.URL}/subadmin/update_admin`,
    GET_MENU_LIST: `${config.apiGateway.URL}/subadmin/get_menu_list`,
    GET_ADMIN_LIST: `${config.apiGateway.URL}/subadmin/get_admin_list`,
    DELETE_ADMIN: `${config.apiGateway.URL}/subadmin/delete_admin`,
    DELETE_USER_GROUP: `${config.apiGateway.URL}/subadmin/delete_user_group`,
    USER_GROUP_DETAIL: `${config.apiGateway.URL}/subadmin/user_group_detail`,

    // ACCOUNT STATEMENT
    ACCOUNT_STATEMENT: `${config.apiGateway.URL}/users/account_statement`,
    USER_STATEMENT: `${config.apiGateway.URL}/reports/user_statement`,
    SEARCH_USER: `${config.apiGateway.URL}/reports/search_user`,

    // BET SETTLEMENT
    GET_SPORTS_BY_BM_SETTLEMENT: `${config.apiGateway.URL}/settlement/get_sports_by_bm`,
    GET_ALL_EVENTS: `${config.apiGateway.URL}/settlement/get_all_events`,
    UPDATE_SETTLEMENT_RESULT: `${config.apiGateway.URL}/settlement/update_settlement_result`,
    CANCEL_MARKET_SETTLEMENT: `${config.apiGateway.URL}/settlement/cancel_market`,
    GET_ALL_SP_EVENTS: `${config.apiGateway.URL}/settlement/get_all_sp_events`,
    GET_ACTIVE_EXCHANGE_FIXTURES: `${config.apiGateway.URL}/settlement/get_active_exchange_fixtures`,
    GET_ACTIVE_SPORTBOOK_FIXTURES: `${config.apiGateway.URL}/settlement/get_active_sportbook_fixtures`,
    CANCEL_SP_MARKET: `${config.apiGateway.URL}/settlement/cancel_sp_market`,
    UPDATE_SP_SETTLEMENT_RESULT: `${config.apiGateway.URL}/settlement/update_sp_settlement_result`,
    

    // Manage Fancy
    GET_ACTIVE_FANCY_FIXTURES: `${config.apiGateway.URL}/fancy/get_active_fancy_fixtures`,
    GET_ACTIVE_FANCY_MARKET: `${config.apiGateway.URL}/fancy/get_active_fancy_markets`,
    SUSPEND_MARKET: `${config.apiGateway.URL}/fancy/suspend_market`,
    ACTIVE_INACTIVE_MARKET: `${config.apiGateway.URL}/fancy/active_inactive_market`,
    CANCEL_MARKET: `${config.apiGateway.URL}/fancy/cancel_market`,
    UPDATE_FANCY_RESULT: `${config.apiGateway.URL}/fancy/update_result`,
    // MARKET WATCH DASHBOARD
    MATCH_DETAIL: `${config.apiGateway.URL}/marketwatch/match_detail`,
    GET_OWN_USERS: `${config.apiGateway.URL}/marketwatch/get_own_users`,
    LOCK_EVENT_BET: `${config.apiGateway.URL}/marketwatch/lock_event_bet`,
    GET_CHILD_USERS: `${config.apiGateway.URL}/marketwatch/get_child_users`,
    LOCK_EVENT_FANCYBET: `${config.apiGateway.URL}/marketwatch/lock_event_fancybet`,
    LOCK_BETTING_MARKET: `${config.apiGateway.URL}/marketwatch/market_lock_betting`,
    LOCK_BACK_BETTING: `${config.apiGateway.URL}/marketwatch/lock_back_betting`,
    LOCK_LAY_BETTING: `${config.apiGateway.URL}/marketwatch/lock_lay_betting`,
    LOCK_TEAM_BETTING: `${config.apiGateway.URL}/marketwatch/lock_team_betting`,
    FANCY_ACTIVE_INACTIVE: `${config.apiGateway.URL}/marketwatch/fancy_active_inactive`,
    FANCY_SUSPEND_MARKET: `${config.apiGateway.URL}/marketwatch/fancy_suspend_market`,
    CANCEL_MARKET_FANCY: `${config.apiGateway.URL}/marketwatch/cancel_market`,
    BET_STATEMENT_DETAIL: `${config.apiGateway.URL}/users/bet_statement_detail`,
    MATCH_DETAIL_SB: `${config.apiGateway.URL}/marketwatch/match_detail_sb`,
    MARKET_LOCK_BETTING_SB: `${config.apiGateway.URL}/marketwatch/market_lock_betting_sb`,
    TEAM_LOCK_BETTING_SB: `${config.apiGateway.URL}/marketwatch/team_lock_betting_sb`,
    UPDATE_TOTAL_CAP:`${config.apiGateway.URL}/marketwatch/update_total_cap`,
    UPDATE_TOTAL_CAP_SB:`${config.apiGateway.URL}/marketwatch/update_total_cap_sb`,
    UPDATE_TOTAL_CAP_CS:`${config.apiGateway.URL}/marketwatch/update_total_cap_cs`,
    UPDATE_USERWISE_TOTAL_CAPS:`${config.apiGateway.URL}/marketwatch/update_userwise_total_caps`,

    //BET-HISTORY     
    BET_HISTORY: `${config.apiGateway.URL}/reports/bet_history`,
    BET_HISTORY_CANCEL_BET: `${config.apiGateway.URL}/reports/cancel_bet`,
    //SPORT SETTING
    GET_SPORTS_LIST: `${config.apiGateway.URL}/sportsettings/get_sports_list`,
    SAVE_SPORTS_SETTING: `${config.apiGateway.URL}/sportsettings/save_sports_settings`,
    UPDATE_SPORTS_STATUS: `${config.apiGateway.URL}/sportsettings/update_sports_status`,
    REMOVE_OLD_TRANSACTIONS: `${config.apiGateway.URL}/settings/remove_old_transactions`,
    //MATCHROLLBACK  
    GET_ALL_EX_ROLLBACK_EVENTS: `${config.apiGateway.URL}/matchrollback/get_all_ex_rollback_events`,
    GET_ALL_SP_ROLLBACK_EVENTS: `${config.apiGateway.URL}/matchrollback/get_all_sp_rollback_events`,
    GET_ALL_FANCY_ROLLBACK_EVENTS: `${config.apiGateway.URL}/matchrollback/get_all_fancy_rollback_events`,
    ROLLBACK_EX_MATCHES: `${config.apiGateway.URL}/matchrollback/rollback_ex_matches`,
    ROLLBACK_FANCY_MATCHES: `${config.apiGateway.URL}/matchrollback/rollback_fancy_matches`,
    ROLLBACK_SP_MATCHES: `${config.apiGateway.URL}/matchrollback/rollback_sp_matches`,
    GET_ACTIVE_EX_ROLLBACK_FIXTURES: `${config.apiGateway.URL}/matchrollback/get_active_ex_rollback_fixtures`,
    GET_ACTIVE_SP_ROLLBACK_FIXTURES: `${config.apiGateway.URL}/matchrollback/get_active_sp_rollback_fixtures`,
    GET_ACTIVE_FANCY_ROLLBACK_FIXTURES: `${config.apiGateway.URL}/matchrollback/get_active_fancy_fixtures`,
    
    
    




    



    


  

    //Reports
    DELETED_BETS_ROLLBACK: `${config.apiGateway.URL}/reports/deleted_bets_rollback`,
    DELETED_BET_HISTORY: `${config.apiGateway.URL}/reports/deleted_bet_history`,
    PROFIT_LOST_BY_MATCH:`${config.apiGateway.URL}/reports/profit_loss_by_match`,
    DOWNLOAD_PROFIT_LOSS_XLSX:`${config.apiGateway.URL}/reports/download_profit_loss_xlsx`,
    DOWNLOAD_PROFIT_LOSS_PDF:`${config.apiGateway.URL}/reports/download_profit_loss_pdf`,
    DOWNLOAD_PL_BY_DOWNLINE_XLSX :`${config.apiGateway.URL}/reports/download_pl_by_downline_xlsx`,
    DOWNLOAD_PL_BY_DOWNLINE_PDF :`${config.apiGateway.URL}/reports/download_pl_by_downline_pdf`,
    PROFIT_LOST_BY_DOWNLINE:`${config.apiGateway.URL}/reports/pl_by_downline`,
    P_L_BY_SPORTS:`${config.apiGateway.URL}/reports/pl_by_sports`,
    P_L_BY_MATCH_BET_DETAILS:`${config.apiGateway.URL}/reports/profit_loss_by_match_bet_detail`,


    //Feed Configuration
    GET_EX_FEED_CONFIGURATION:`${config.apiGateway.URL}/settings/get_ex_feed_provider`,
    SWITCH_EX_PROVIDER:`${config.apiGateway.URL}/settings/switch_ex_provider`,

    //Bookmaker 
    MARKET_BOOKMAKER_LOCK_BETTING:`${config.apiGateway.URL}/marketwatch/market_bookmaker_lock_betting`,
    LOCK_BOOKMAKER_BACK_BETTING:`${config.apiGateway.URL}/marketwatch/lock_bookmaker_back_betting`,
    LOCK_BOOKMAKER_LAY_BETTING:`${config.apiGateway.URL}/marketwatch/lock_bookmaker_lay_betting`,
    LOCK_BOOKMAKER_TEAM_BETTING:`${config.apiGateway.URL}/marketwatch/lock_bookmaker_team_betting`,
    GET_ALL_BOOKMAKER_EVENTS: `${config.apiGateway.URL}/settlement/get_all_bookmaker_events`,
    UPDATE_SETTLEMENT_BOOKMAKER_RESULT: `${config.apiGateway.URL}/settlement/update_settlement_bookmaker_result`,
    CANCEL_BOOKMAKER_MARKET: `${config.apiGateway.URL}/settlement/cancel_bookmaker_market`,
    GET_BOOKMAKER_MARKET: `${config.apiGateway.URL}/matches/get_bookmaker_market`,
    UPDATE_BOOKMAKER_MARKET_STATUS: `${config.apiGateway.URL}/matches/update_bookmaker_market_status`,
    GET_ALL_BOOKMAKER_EVENTS_ROLLBACK: `${config.apiGateway.URL}/matchrollback/get_all_bookmaker_events`,
    ROLLBACK_BOOKMAKER_MATCHES: `${config.apiGateway.URL}/matchrollback/rollback_bookmaker_matches`,
    GET_ACTIVE_BOOKMAKER_ROLLBACK_FIXTURES: `${config.apiGateway.URL}/matchrollback/get_active_bookmaker_rollback_fixtures`,

    // admin new apis
    GET_BANNER_TYPE: `${config.apiGateway.URL}/banner/get_banner_type`,
    CREATE_BANNER_TYPE: `${config.apiGateway.URL}/banner/create_banner_type`,
    GET_BANNER_TYPE_STATUS: `${config.apiGateway.URL}/banner/update_banner_type_status`,
    GET_BANNERS: `${config.apiGateway.URL}/banner/get_banners`,
    GET_BANNERS_UPDATE_STATUS: `${config.apiGateway.URL}/banner/update_status`,
    CREATE_BANNERS: `${config.apiGateway.URL}/banner/create_banner`,
    IMAGE_UPLOAD: `${config.apiGateway.URL}/banner/do_upload`,
    BANNER_DETAIL: `${config.apiGateway.URL}/banner/get_banner_detail`,
    UPDATE_BANNER_ORDER: `${config.apiGateway.URL}/banner/update_banner_order`,
    UPDATE_BANNERS: `${config.apiGateway.URL}/banner/edit_banner`,
    UPDATE_SPORTS_ORDER: `${config.apiGateway.URL}/sportsettings/update_sports_order`,
    MARKET_WATCH_FILTER: `${config.apiGateway.URL}/marketwatch/get_sb_filter`,
    GET_TEAMLIST: `${config.apiGateway.URL}/team/get_teams`,
    UPDATE_FIXTURE_STATUS: `${config.apiGateway.URL}/marketwatch/update_fixture_status`,
    TEAM_UPLOAD: `${config.apiGateway.URL}/team/do_upload`,
    TEAM_UPDATE: `${config.apiGateway.URL}/team/edit_image`,
    DELETE_BANNER_TYPE: `${config.apiGateway.URL}/banner/delete_banner_type`,
    MARKET_HISTORY_FILTER: `${config.apiGateway.URL}/reports/get_bethistory_filter`,
    //bet template h2h
    GET_BET_TEMPLATE: `${config.apiGateway.URL}/matches/get_bet_template`,
    GET_BET_TEMPLATE_BY_TYPE: `${config.apiGateway.URL}/matches/get_bet_template_by_type`,
    GET_H2H_FORM_DROPDOWN: `${config.apiGateway.URL}/matches/get_player`,
    GET_H2H_FORM_TEAM_DROPDOWN: `${config.apiGateway.URL}/matches/get_team`,
    SAVE_TEMPLATE_QUESTION: `${config.apiGateway.URL}/matches/save_template_question`,
    MASTER_OPTION_H2H: `${config.apiGateway.URL}/matches/get_master_option`,
    GET_QUESTION_BY_TEMPID: `${config.apiGateway.URL}/matches/get_question_by_template_id `,
    UPDATE_QUESTION_STATUS: `${config.apiGateway.URL}/matches/update_question_status `,
    UPDATE_TEMPLATE_STATUS: `${config.apiGateway.URL}/matches/update_template_status `,
    MANUAL_ANSWER_MARK: `${config.apiGateway.URL}/matches/manual_answer_mark `,
    CANCEL_QUESTION: `${config.apiGateway.URL}/matches/cancel_question `,
   

    //pool bet
    GET_POOLBET_TEMPLATE: `${config.apiGateway.URL}/poolbet/get_poolbet_type `,
    GET_POOLBET_CONTESTLIST: `${config.apiGateway.URL}/poolbet/get_poolbet_contest `,
    UPDATE_POOLBET_CONTEST_STATUS: `${config.apiGateway.URL}/poolbet/update_contest_status `,
    POOLBET_CANCEL_CONTEST: `${config.apiGateway.URL}/poolbet/contest_cancel `,
    POOLBET_TEMPLATE_TYPE: `${config.apiGateway.URL}/poolbet/get_poolbet_template_type `,
    CREATE_POOLBET: `${config.apiGateway.URL}/poolbet/create_poolbet `,
    GET_POOLBET_QUESTION: `${config.apiGateway.URL}/poolbet/get_poolbet_question `,
    UPDATE_POOLBET_QUEST_STATUS: `${config.apiGateway.URL}/poolbet/update_status_quest_poolbet `,
    POOLBET_MANUAL_ANSWER_MARK: `${config.apiGateway.URL}/poolbet/manual_answer_mark `,
    GET_POOLBET_TEMPLATE_LIST: `${config.apiGateway.URL}/poolbet/get_poolbet_template_list `,
    POOLBET_TEMPLATE_STATUS: `${config.apiGateway.URL}/poolbet/template_status_for_poolbet `,
    POOLBET_QUE_LIST_BY_TEMPLATE: `${config.apiGateway.URL}/poolbet/get_question_list_by_template `,
    POOLBET_QUE_BY_TEMPLATE_STATUS: `${config.apiGateway.URL}/poolbet/update_question_in_contest `,
    POOLBET_SAVE_TEMPLATE_QUESTION: `${config.apiGateway.URL}/poolbet/save_template_question `,
    
    //bookmark logs
    GET_BOOKMARK_LOGS: `${config.apiGateway.URL}/reports/bookmark_log `,
    BOOKMARK_LOG_FILTER: `${config.apiGateway.URL}/reports/bookmark_log_filter`,
    EXPORT_BOOKMARK_LOGS: `${config.apiGateway.URL}/reports/export_bookmark_log`,

    //marketWatch new
    UPDATE_MARKET_STATUS: `${config.apiGateway.URL}/marketwatch/update_market_status`,
    PROVIDER_FILTER: `${config.apiGateway.URL}/marketwatch/provider_filter`,
    UPDATE_AUTO_SETTLEMENT: `${config.apiGateway.URL}/marketwatch/update_autsetelment_status`,
    MARKET_DETAIL: `${config.apiGateway.URL}/marketwatch/market_detail_sb`,
    SET_PROVIDER: `${config.apiGateway.URL}/marketwatch/update_provider`,
    ENABLE_ALL_MARKETS: `${config.apiGateway.URL}/marketwatch/update_all_market_status`
    
}

export default {
    SUCCESS_CODE,
    ...API
};
