const Role = {
  User: 'User',    
  SuperAdmin: '1',
  Admin: '2',
  SuperMaster: '3',
  Master: '4',
  Staff: '5',
  Punter: '6',
  SuperAdminAgent:'7',
  Agent:'8',
  SubAgent :'9',
  AgentPunter:'10',
  AgentStaff :'11' 
}

export default Role;