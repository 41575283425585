/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, Row, Col, FormGroup, Input, Label } from 'reactstrap';
import DatePicker from 'react-date-picker';
import { Utils, Http, API } from 'Helpers';
import moment from 'moment';
import {CONSTANT} from 'Constants';

class AddUserModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posting: false,
      formValid: false,
      name: '',
      username: '',
      password: '',
      confirm_password: '',
      parent_guid: '',
      opening_balance: '',
      opening_date: null,
      role: '',
      remark: '',
      reference:'',
      max_reference_value:CONSTANT.MAX_REFERENCE_VALUE
    }
  }


  handleChange = (e, type) => {
    const name = !type ? e.target.name : type;
    let value = !type ? e.target.value : e;
    if (name === 'password') {
      value = value.trim()
    }
    this.setState({ [name]: value }, () => {
      this.validateForm()
    });
  };

  comparePassword() {
    const { confirm_password, password } = this.state;
    return confirm_password != '' && confirm_password != password
  }


  validateForm() {
    const {
      name,
      username,
      password,
      confirm_password,
      opening_balance,
      opening_date,
      reference,
      max_reference_value
    } = this.state;
    this.setState({
      formValid:
        name != ''
        && username != ''
        && opening_date != null
        && opening_balance != ''
        && password != ''
        && confirm_password != ''
        // && remark != ''
        && Utils.isInvalid("required", name) == false
        && Utils.isInvalid("username", username) == false
        && Utils.isInvalid("date", opening_date) == false
        && Utils.isInvalid("number", opening_balance) == false
        && Utils.isInvalid("password", password) == false
        // && Utils.isInvalid("required", remark) == false
        && this.comparePassword() == false
        && Utils.isInvalid("Password", password) == false
        && reference != ''
        && Utils.isInvalid("number", reference) == false
        && reference >= 0
        && reference <= max_reference_value
    });
  }


  onDateChange = (opening_date) => {
    this.setState({ opening_date }, this.validateForm)
  }

  onCalendarOpen = () => {
    if (this.state.opening_date == '' || this.state.opening_date == null) {
      this.setState({ opening_date: new Date() }, this.validateForm)
    }
  }
  getRole(UserProps) {
    return UserProps.role == 1 ? 2 : UserProps.role == 2 ? 3 : UserProps.role == 3 ? 4 : 6;
  }
  onSubmit = (e) => {
    e.preventDefault();

    if(!this.state.formValid)return;

    this.setState({ posting: true })
    const { UserProps } = this.props
    const {
      name,
      username,
      password,
      confirm_password,
      opening_balance,
      opening_date,
      remark,
      reference
    } = this.state;

    let param = {
      name,
      username,
      password,
      confirm_password,
      opening_balance,
      opening_date,
      remark,
      parent_guid: UserProps.user_guid,
      role: this.getRole(UserProps),
      reference
    }

    Http.post(API.ADD_USER, param).then(response => {
      this.setState({ posting: false }, this.props.callBack)
      Utils.notify(response.message);
    }).catch(error => {
      this.setState({ posting: false })
    });
  }

  render() {
    const { isOpen, toggle, ParentUserProps, UserProps } = this.props
    const ModalProps = {
      isOpen,
      toggle,
      size: 'lg',
      className: 'custom-modal'
    }
    const {
      posting,
      formValid,
      name,
      username,
      password,
      confirm_password,
      opening_balance,
      opening_date,
      remark,
      reference,
      max_reference_value
    } = this.state

    return (
      <Modal {...ModalProps}>
        <i className="close-btn icon-cancel" onClick={toggle} />
        <ModalHeader>Add User</ModalHeader>
        <Form onSubmit={this.onSubmit} >
          <ModalBody>
            <Row>

              {ParentUserProps ? <Label className="bold-label">Parent Balance: {ParentUserProps.balance} || User Balance: {UserProps.balance}</Label>
                : <Label className="bold-label">User Balance: {UserProps.balance}</Label>}

            </Row>
            <Row>
              <Col xs={12} md={4}>
                <FormGroup>
                  <Label>Name</Label>
                  <Input
                    type="text"
                    name="name"
                    value={name}
                    onChange={this.handleChange}
                    autoComplete="off"
                    invalid={Utils.isInvalid("required", name)}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={4}>
                <FormGroup>
                  <Label>User ID</Label>
                  <Input
                    id='username'
                    type="text"
                    name="username"
                    value={username}
                    onChange={this.handleChange}
                    autoComplete="off"
                    invalid={Utils.isInvalid("username", username)} />
                </FormGroup>
              </Col>
              <Col xs={12} md={4}>
                <FormGroup>
                  <Label>Opening Date</Label>
                  <DatePicker
                    onChange={this.onDateChange}
                    onCalendarOpen={this.onCalendarOpen}
                    value={opening_date}
                    calendarIcon={<i className='icon-calendar' />}
                    className='form-control'
                    clearIcon={null}
                    minDate={moment().toDate()}
                  // maxDate={Utils.getDateYearsOld()}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={4}>
                <FormGroup>
                  <Label>Opening Balance</Label>
                  <Input
                    type="text"
                    name="opening_balance"
                    value={opening_balance}
                    onChange={this.handleChange}
                    autoComplete="off"
                    invalid={Utils.isInvalid("number", opening_balance)}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={4}>
                <FormGroup>
                  <Label>Password</Label>
                  <Input
                    type="password"
                    name="password"
                    value={password}
                    autoComplete="new-password"
                    onChange={this.handleChange}
                    invalid={Utils.isInvalid("Password", password)}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={4}>
                <FormGroup>
                  <Label>Confirm Password</Label>
                  <Input
                    type="password"
                    name="confirm_password"
                    value={confirm_password}
                    autoComplete="off"
                    onChange={this.handleChange}
                    invalid={this.comparePassword()}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={4}>
                <FormGroup>
                  <Label>Reference</Label>
                  <Input
                    type="text"
                    name="reference"
                    value={reference}
                    autoComplete="off"
                    onChange={this.handleChange}
                    invalid={Utils.isInvalid("number", reference) || reference < 0 || reference > max_reference_value}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Remark</Label>
                  <Input
                    type="textarea"
                    name="remark"
                    value={remark}
                    onChange={this.handleChange}
                    autoComplete="off"
                    invalid={Utils.isInvalid("required", remark) }
                  />
                </FormGroup>
              </Col>
            </Row>


          </ModalBody>
          <ModalFooter>
            <Button color="primary" size='sm' type='submit' disabled={!formValid || posting}>Create</Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}

AddUserModal.defaultProps = {
  UserProps: {},
  isOpen: true,
  toggle: () => { }
}

export default AddUserModal;
